import React from 'react';
import cx from 'classnames';

import ClusterLayout from '../ClusterLayout';

import stylesheet from './Modal.less';

export type ModalFooterProps = {
  /** The footer content inside the modal.  */
  children?: React.ReactNode;
  /** Change the content justification for the footer. To ensure design consistency, Only a subset (flex-end and `space-between`)
   * of normal justification choices are available.
   */
  justifyContent?: 'flex-end' | 'space-between';
  /** Additional class names to apply to the footer. */
  className?: string;
};

const ModalFooter = ({
  children,
  className,
  justifyContent = 'flex-end',
  ...rest
}: ModalFooterProps) => {
  return (
    <div className={cx(stylesheet.footer, className)} {...rest}>
      <ClusterLayout justifyContent={justifyContent}>{children}</ClusterLayout>
    </div>
  );
};

export default ModalFooter;
