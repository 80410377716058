import React, { useCallback, useEffect } from 'react';
import cx from 'classnames';

import { CrossIcon } from '@mc/wink-icons';
// eslint-disable-next-line no-restricted-imports
import { useTransition, animated } from 'react-spring';
import usePrefersReducedMotion from '@mc/hooks/usePrefersReducedMotion';
import { useDsTranslateMessage } from '@mc/wink/internationalization/useDsTranslateMessage';
import IconButton from '../IconButton';
import Portal from '../Portal';
import stylesheet from './Toast.less';

export type ToastProps = {
  action?: React.ReactElement;
  children: React.ReactNode;
  icon?: React.ReactNode;
  inline?: boolean;
  onClose?: $TSFixMeFunction;
  tunnelId?: string;
};

/** An alert in response to user actions. */
const Toast = React.forwardRef<$TSFixMe, ToastProps>(function Toast(
  {
    action,
    // @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type 'Props... Remove this comment to see the full error message
    className,
    children,
    icon,
    onClose,
    tunnelId = 'portal-notification',
    inline = false,
    ...rest
  },
  forwardedRef,
) {
  const classList = cx(stylesheet.root, className, 'dark-mode');
  const prefersReducedMotion = usePrefersReducedMotion();

  const mountNode = useCallback(() => {
    return document.getElementById(tunnelId);
  }, [tunnelId]);

  const onKeyDownHandler = useCallback(
    ({ key, defaultPrevented }: $TSFixMe) => {
      if (!defaultPrevented && key === 'Escape') {
        // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    document.addEventListener('keydown', onKeyDownHandler, { capture: true });
    return () => {
      document.removeEventListener('keydown', onKeyDownHandler, {
        capture: true,
      });
    };
  }, [onKeyDownHandler]);

  // Translation for default icon button label text
  const iconButtonLabelText = useDsTranslateMessage({
    id: 'mcds.toast.icon_button_label_text',
    defaultMessage: 'Dismiss notification',
  });

  const message = (
    <div className={classList} ref={forwardedRef} {...rest}>
      <div className={stylesheet.wrapper}>
        {icon && <span className={stylesheet.icon}>{icon}</span>}
        <div className={stylesheet.content}>
          <div className={stylesheet.copy}>{children}</div>
          <div>{action}</div>
        </div>
        <span>
          <IconButton
            label={iconButtonLabelText}
            onClick={onClose}
            icon={<CrossIcon />}
          />
        </span>
      </div>
    </div>
  );

  const animationConfig = {
    from: { opacity: 0, transform: 'translate3d(0%,90%,0)' },
    enter: { opacity: 1, transform: 'translate3d(0,0%,0)' },
    config: {
      mass: 1,
      tension: 620,
      friction: 28,
    },
  };

  const transitions = useTransition(
    true,
    prefersReducedMotion ? {} : animationConfig,
  );

  return !inline ? (
    // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; mountNode: () => HTMLEl... Remove this comment to see the full error message
    <Portal mountNode={mountNode}>
      {transitions((style) => (
        <animated.div style={style}>{message}</animated.div>
      ))}
    </Portal>
  ) : (
    message
  );
});

export default Toast;
