import React from 'react';
import cx from 'classnames';

import stylesheet from './Popover.less';

export type PopoverBodyProps = {
  children?: React.ReactNode[] | React.ReactNode;
  className?: string;
};

function PopoverBody({ children, className, ...props }: PopoverBodyProps) {
  return (
    <div className={cx(stylesheet.body, className)} {...props}>
      {children}
    </div>
  );
}

export default PopoverBody;
