import React from 'react';

import { mcdsFlagCheck } from '@mc/wink/helpers/utils-ts';
import ClusterLayout from '../ClusterLayout';
import StackLayout from '../StackLayout';
import Heading from '../Heading';
import ContentArea from '../ContentArea';
import Text from '../Text';

import stylesheet from './DetailHero.css';

export type DetailHeroProps = {
  /** Usually contains DataWells or any other complementary content. */
  children?: React.ReactNode;
  /** Can contain a badge and/or meta information. */
  eyebrow?: React.ReactNode;
  /** Displays an id profile when an `img` element is provided. */
  image?: React.ReactNode;
  /** Will normally be a small body of text. */
  secondary?: string;
  /** Title will be a h2 that has an h1 appearance. */
  title: string;
};

const DetailHero = React.forwardRef<HTMLDivElement, DetailHeroProps>(
  function DetailHero(
    { eyebrow, title, children, secondary, image },
    forwardedRef,
  ) {
    return (
      <ContentArea className={stylesheet.root} ref={forwardedRef}>
        <ClusterLayout alignItems="flex-start" gap={7}>
          {image && <div>{image}</div>}
          <div
            style={{
              flexGrow: 1,
            }}
          >
            <StackLayout gap={2}>
              {eyebrow && <div>{eyebrow}</div>}
              <Heading
                level={2}
                appearance={
                  mcdsFlagCheck('xp_project_runway_design_foundation')
                    ? 'heading-3'
                    : 'heading-2'
                }
              >
                {title}
              </Heading>
              {secondary && (
                <Text appearance="medium" className={stylesheet.secondary}>
                  {secondary}
                </Text>
              )}
            </StackLayout>
            <div>{children}</div>
          </div>
        </ClusterLayout>
      </ContentArea>
    );
  },
);

export default DetailHero;
