import React, { useState, useRef } from 'react';
import cx from 'classnames';
import useJoinedRef from '@mc/hooks/useJoinedRef';
import chainHandlers from '@mc/fn/chainHandlers';
import useId from '@mc/hooks/useId';
import { useDsTranslateMessage } from '@mc/wink/internationalization/useDsTranslateMessage';
import {
  formatError,
  ERROR_MUST_PROVIDE_LABEL,
  ariaDescribedByIds,
  ariaLabelledByIds,
} from '../utils';
import Tooltip from '../Tooltip';
import stylesheet from './Slider.less';

const getAllTicks = (min: $TSFixMe, max: $TSFixMe, step: $TSFixMe) => {
  const allTicks = [];
  for (let i = min; i <= max; i += step) {
    allTicks.push(i);
  }
  return allTicks;
};

export type SliderProps = {
  _?: $TSFixMe; // TODO: (props, propName, componentName) => { if (!props.label && !props['aria-labelledby']) { return new Error(formatError(ERROR_MUST_PROVIDE_LABEL, componentName)); } }
  anchorIcons?: $TSFixMe[];
  'aria-labelledby'?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  error?: string;
  helpText?: React.ReactNode;
  hideLabel?: boolean;
  hideOutput?: boolean;
  label?: React.ReactNode;
  max: number;
  min?: number;
  onBlur?: $TSFixMeFunction;
  onChange: $TSFixMeFunction;
  onFocus?: $TSFixMeFunction;
  readOnly?: boolean;
  renderOutput?: $TSFixMeFunction;
  step?: number;
  value: number;
};

const Slider = React.forwardRef<$TSFixMe, SliderProps>(function Slider(
  {
    'aria-labelledby': ariaLabelledBy,
    anchorIcons = [],
    children,
    // @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type 'Props... Remove this comment to see the full error message
    className,
    disabled = false,
    readOnly = false,
    helpText,
    error,
    step = 1,
    min = 0,
    max,
    hideLabel,
    hideOutput,
    label,
    // Unused in Slider but may be provided via FormField.
    // @ts-expect-error TS(2339) FIXME: Property 'miscText' does not exist on type 'Props'... Remove this comment to see the full error message
    // eslint-disable-next-line react/prop-types
    miscText,
    onChange,
    onBlur = () => {},
    onFocus = () => {},
    renderOutput = (v) => v,
    value,
    ...props
  },
  forwardedRef,
) {
  const [hasFocus, setHasFocus] = useState(false);
  const ref = useRef();
  const rangeRef = useJoinedRef(forwardedRef, ref);
  const id = useId();
  const labelId = useId();
  const helpTextId = useId();
  const miscTextId = useId();
  const [PrefixIcon, SuffixIcon] = anchorIcons;
  const fillWidth = ((value - min) / (max - min)) * 100;
  const allTicks = getAllTicks(min, max, step);
  const ticksByValue = {};

  const outputSelectedLabelText = useDsTranslateMessage({
    id: 'mcds.slider.output_selected_label_text',
    defaultMessage: 'Selected value:',
  });

  React.Children.forEach(children, (child) => {
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    ticksByValue[(child as $TSFixMe).props.value] = (child as $TSFixMe).props;
  });

  return (
    <div
      className={cx(stylesheet.root, className, {
        [stylesheet.error]: !!error,
      })}
    >
      <div className={stylesheet.before}>
        {label && (
          <label
            className={cx(
              'mcds-label-default',
              hideLabel && 'wink-visually-hidden',
            )}
            id={labelId}
            htmlFor={id}
          >
            {label}
          </label>
        )}
        {/* <output> is implemented as an aria-live region by some browsers and will re-announce this as value changes. */}
        <output
          id={miscTextId}
          className={cx({
            'wink-visually-hidden': hideOutput,
          })}
        >
          <span className="wink-visually-hidden">
            {outputSelectedLabelText}
          </span>
          {renderOutput(value)}
        </output>
      </div>
      <div className={stylesheet.rangeContainer}>
        {PrefixIcon && <PrefixIcon />}
        <div
          className={cx(stylesheet.range, {
            [stylesheet.withAnchorIcons]: anchorIcons.length > 0,
          })}
        >
          <input
            type="range"
            id={id}
            // We need to handle three cases:
            //
            // 1. Only pass a `label`. Since we're using a native label element,
            //    pointing `aria-labelledby` to the existing label element is
            //    unnecessary.
            // 2. Only pass an `aria-labelledby`. We don't render a label
            //    element.
            // 3. Pass both a `label` and `aria-labelledby`. We refer to both in
            //    the `aria-labelledby` attribute.
            aria-labelledby={ariaLabelledByIds(
              ariaLabelledBy,
              ariaLabelledBy && label && labelId,
            )}
            aria-describedby={ariaDescribedByIds(
              (error || helpText) && helpTextId,
              miscText && miscTextId,
            )}
            value={value}
            onChange={(event) => {
              onChange(Number(event.target.value));
            }}
            min={min}
            max={max}
            step={step}
            ref={rangeRef}
            disabled={disabled}
            readOnly={readOnly}
            list={id + '-ticks'}
            {...props}
            onFocus={chainHandlers(onFocus, () => setHasFocus(true))}
            onBlur={chainHandlers(onBlur, () => setHasFocus(false))}
          />
          {children && <datalist id={id + '-ticks'}>{children}</datalist>}
          <div
            style={{ width: `${fillWidth}%` }}
            className={stylesheet.track}
          />
          <div className={stylesheet.ticks}>
            {allTicks.map((tick) =>
              // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              ticksByValue[tick] ? (
                <Tooltip
                  key={tick}
                  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  label={ticksByValue[tick].label || ticksByValue[tick].value}
                  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  forceVisible={hasFocus && ticksByValue[tick].value === value}
                  direction="bottom"
                >
                  <div className={stylesheet.tick}> </div>
                </Tooltip>
              ) : (
                <div
                  key={tick}
                  style={{
                    visibility: 'hidden',
                  }}
                  className={stylesheet.tick}
                />
              ),
            )}
          </div>
        </div>
        {SuffixIcon && <SuffixIcon />}
      </div>
      {error ? (
        <span id={helpTextId} className={stylesheet.errorMessage}>
          {error}
        </span>
      ) : helpText ? (
        <span id={helpTextId} className={stylesheet.helpText}>
          {helpText}
        </span>
      ) : null}
    </div>
  );
});

export default Slider;
