import React from 'react';
import cx from 'classnames';

import { MenuDownIcon } from '@mc/wink-icons';
import chainHandlers from '@mc/fn/chainHandlers';
import useId from '@mc/hooks/useId';
import Listbox from '../Listbox';
import {
  formatError,
  ERROR_MUST_PROVIDE_LABEL,
  ariaDescribedByIds,
  ariaLabelledByIds,
} from '../utils';
import emulateSelectKeyboardSearch from './emulateSelectKeyboardSearch';
import stylesheet from './Select.less';

type InputListboxTriggerProps = {
  filter?: string;
  id: string;
  isExpanded: boolean;
  onBlur: $TSFixMeFunction;
  onFilterChange: $TSFixMeFunction;
  onHighlight?: $TSFixMeFunction;
  onKeyDown: $TSFixMeFunction;
  onSelect?: $TSFixMeFunction;
  onToggle: $TSFixMeFunction;
  options?: $TSFixMe[];
  placeholder?: React.ReactNode;
  renderSelectedValue?: $TSFixMeFunction;
  selected?: $TSFixMe[];
};

const InputListboxTrigger = React.forwardRef<
  $TSFixMe,
  InputListboxTriggerProps
>(
  (
    {
      filter,
      onFilterChange,
      onBlur,
      onToggle,
      id,
      selected = [],
      placeholder,
      renderSelectedValue,
      isExpanded,
      options,
      onHighlight,
      onSelect,
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <input
        autoComplete="off"
        {...props}
        id={id}
        ref={forwardedRef}
        className={cx(stylesheet.trigger, stylesheet.inputTrigger)}
        // @ts-expect-error TS(2322) FIXME: Type 'ReactNode' is not assignable to type 'string... Remove this comment to see the full error message
        placeholder={placeholder}
        value={
          // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
          filter === undefined ? renderSelectedValue(selected, '') : filter
        }
        onChange={(event) => {
          onFilterChange(event.target.value);
        }}
        onClick={() => {
          onToggle();
        }}
        onBlur={(event) => {
          onFilterChange(undefined);
          onBlur(event);
        }}
      />
    );
  },
);

type SelectListboxTriggerProps = {
  filter?: string;
  isExpanded: boolean;
  onBlur: $TSFixMeFunction;
  onFilterChange?: $TSFixMeFunction;
  onHighlight: $TSFixMeFunction;
  onKeyDown: $TSFixMeFunction;
  onSelect: $TSFixMeFunction;
  onToggle: $TSFixMeFunction;
  options: $TSFixMe[];
  placeholder?: React.ReactNode;
  renderSelectedValue?: $TSFixMeFunction;
  selected?: $TSFixMe[];
};

const SelectListboxTrigger = React.forwardRef<
  $TSFixMe,
  SelectListboxTriggerProps
>(
  (
    {
      selected = [],
      placeholder,
      renderSelectedValue,
      options,
      isExpanded,
      filter,
      onFilterChange,
      onBlur,
      onKeyDown,
      onHighlight,
      onSelect,
      onToggle,
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <div
        tabIndex={0}
        className={stylesheet.trigger}
        {...props}
        ref={forwardedRef}
        onBlur={onBlur}
        onKeyDown={chainHandlers(onKeyDown, (event: $TSFixMe) => {
          emulateSelectKeyboardSearch(event, {
            options,
            isExpanded,
            onSelect,
            onHighlight,
            onToggle,
          });
        })}
        onClick={onToggle}
      >
        {/* @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message */}
        {renderSelectedValue(selected, placeholder)}
      </div>
    );
  },
);

export type SelectProps = {
  _?: $TSFixMe; // TODO: (props, propName, componentName) => { if (!props.label && !props['aria-labelledby']) { return new Error(formatError(ERROR_MUST_PROVIDE_LABEL, componentName)); } }
  'aria-labelledby'?: string;
  callToActionHref?: string;
  callToActionLabel?: string;
  callToActionOnClick?: $TSFixMeFunction;
  children: React.ReactNode;
  disabled?: boolean;
  error?: string;
  helpText?: React.ReactNode;
  hideLabel?: boolean;
  label?: React.ReactNode;
  matchTargetWidth?: boolean;
  miscText?: React.ReactNode;
  mode?: 'native' | 'listbox';
  multiple?: boolean;
  onChange: $TSFixMeFunction;
  onFocus?: $TSFixMeFunction;
  readOnly?: boolean;
  renderSelectedValue?: $TSFixMeFunction;
  searchable?: boolean;
  value?: $TSFixMe;
};

const Select = React.forwardRef<$TSFixMe, SelectProps>(function Select(
  {
    'aria-labelledby': ariaLabelledBy,
    callToActionHref,
    callToActionLabel,
    callToActionOnClick,
    // @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type 'Selec... Remove this comment to see the full error message
    className,
    children,
    mode = 'native',
    searchable = false,
    multiple = false,
    disabled = false,
    readOnly = false,
    hideLabel = false,
    helpText,
    error,
    label,
    onChange,
    matchTargetWidth = true,
    miscText,
    renderSelectedValue,
    ...props
  },
  forwardedRef,
) {
  const id = useId();
  const labelId = useId();
  const helpTextId = useId();
  const miscTextId = useId();
  const describedBy = ariaDescribedByIds(
    (error || helpText) && helpTextId,
    miscText && miscTextId,
  );

  const isListbox = mode === 'listbox' || searchable || multiple;

  // We need to handle three cases:
  //
  // 1. Only pass a `label`. Native selects use a native label element, but
  //    Listbox isn't a native select so it must manually use `aria-labelledby`.
  // 2. Only pass an `aria-labelledby`. We don't render a label element.
  // 3. Pass both a `label` and `aria-labelledby`. We refer to both in the
  //    `aria-labelledby` attribute.
  const labelledBy = ariaLabelledByIds(
    ariaLabelledBy,
    (ariaLabelledBy || isListbox) && label && labelId,
  );

  return (
    <div
      className={cx(stylesheet.root, className, {
        [stylesheet.error]: !!error,
      })}
    >
      <div className={stylesheet.before}>
        {label && (
          <label
            className={cx(
              'mcds-label-default',
              hideLabel && 'wink-visually-hidden',
            )}
            id={labelId}
            htmlFor={id}
          >
            {label}
          </label>
        )}
        {miscText && (
          <span id={miscTextId} className={stylesheet.secondary}>
            {miscText}
          </span>
        )}
      </div>
      <div className={stylesheet.selectWrapper}>
        {isListbox ? (
          <Listbox
            callToActionOnClick={callToActionOnClick}
            callToActionHref={callToActionHref}
            callToActionLabel={callToActionLabel}
            matchTargetWidth={matchTargetWidth}
            multiple={multiple}
            // @ts-expect-error TS(2322) FIXME: Type 'ForwardRefExoticComponent<InputListboxTrigge... Remove this comment to see the full error message
            trigger={searchable ? InputListboxTrigger : SelectListboxTrigger}
            renderSelectedValue={renderSelectedValue}
            disabled={disabled}
            readOnly={readOnly}
            id={id}
            aria-labelledby={labelledBy}
            aria-describedby={describedBy}
            onChange={onChange}
            ref={forwardedRef}
            {...props}
          >
            {children}
          </Listbox>
        ) : (
          <select
            disabled={disabled}
            // @ts-expect-error TS(2322) FIXME: Type '{ children: ReactNode; _?: any; onFocus?: $T... Remove this comment to see the full error message
            readOnly={readOnly}
            id={id}
            aria-labelledby={labelledBy}
            aria-describedby={describedBy}
            onChange={(event) => onChange(event.target.value)}
            ref={forwardedRef}
            {...props}
          >
            {children}
          </select>
        )}

        <div className={stylesheet.indicator}>
          <MenuDownIcon />
        </div>
      </div>
      {error ? (
        <div
          id={helpTextId}
          className={cx(stylesheet.after, stylesheet.errorMessage)}
        >
          {error}
        </div>
      ) : helpText ? (
        <div
          id={helpTextId}
          className={cx(stylesheet.after, stylesheet.secondary)}
        >
          {helpText}
        </div>
      ) : null}
    </div>
  );
});

export default Select;
