import React from 'react';
import cx from 'classnames';
import Input from '../Input';
import stylesheet from './InputNumber.less';

export type InputNumberProps = {
  helpText?: React.ReactNode;
  hideLabel?: boolean;
  inputMode?: 'numeric' | 'decimal' | 'tel';
  label?: React.ReactNode;
  maxLength?: number;
  minLength?: number;
  onChange: $TSFixMeFunction;
  pattern?: string;
  type?: 'text' | 'number';
  value: string;
};

const InputNumber = React.forwardRef<$TSFixMe, InputNumberProps>(
  function InputNumber(
    {
      // @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type 'Props... Remove this comment to see the full error message
      className,
      inputMode = 'numeric',
      pattern = '[0-9]*',
      maxLength,
      minLength,
      type = 'text',
      ...rest
    },
    forwardedRef,
  ) {
    return (
      <Input
        {...rest}
        className={cx(stylesheet.inputNumber, className)}
        ref={forwardedRef}
        type={type}
        inputMode={inputMode}
        pattern={pattern}
        maxLength={maxLength}
        minLength={minLength}
      />
    );
  },
);

export default InputNumber;
