import React, {
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useReducer,
  useMemo,
  useRef,
} from 'react';
import { produce } from 'immer';
import useUpdateEffect from '@mc/hooks/useUpdateEffect';
import usePrevious from '@mc/hooks/usePrevious';
import { useDsTranslateMessage } from '@mc/wink/internationalization/useDsTranslateMessage';

export type WizardStepProps = {
  component: $TSFixMe;
  displayName?: string;
  isCurrentStep?: boolean;
  name: string;
};

/**
 * Wrapper component that renders a single step of a Wizard.
 *
 * To navigate to this step, pass the name to `useWizardActions()`'s
 * `navigate(stepName)` function.
 *
 * If `isCurrentStep` is false, the passed `component` is not rendered at all.
 * If `isCurrentStep` is true, the passed `component` is rendered.
 */
const WizardStep = ({
  isCurrentStep = false,
  component: Component,
  // `name` and `displayName` are used by Wizard, not WizardStep.
  // eslint-disable-next-line no-unused-vars
  name,
  // eslint-disable-next-line no-unused-vars
  displayName,
  ...props
}: WizardStepProps) => {
  const InitializedComponent = React.isValidElement(Component) ? (
    React.cloneElement(Component, { ...props })
  ) : (
    <Component {...props} />
  );

  return isCurrentStep ? InitializedComponent : null;
};

const reducer = (state: $TSFixMe, action: $TSFixMe) => {
  switch (action.type) {
    case 'NAVIGATE': {
      return { ...state, currentStep: action.stepName };
    }

    case 'SET_INVENTORY': {
      return {
        ...state,
        inventory: produce(state.inventory, action.inventoryFn),
      };
    }

    case 'APPEND_SET_INVENTORY_CALLBACK': {
      const callbacks = state.inventoryCallbacks;
      return {
        ...state,
        inventoryCallbacks: [...callbacks, action.callback],
      };
    }

    case 'RESET_SET_INVENTORY_CALLBACKS': {
      return {
        ...state,
        inventoryCallbacks: [],
      };
    }

    case 'UPDATE_STEPS': {
      return {
        ...state,
        stepNames: action.stepNames,
        displayNames: action.displayNames,
        // Update currentStep if provided.
        currentStep: action.currentStep || state.currentStep,
      };
    }

    default:
      return state;
  }
};

/**
 * Helper function that maps children to a specific prop.
 *
 * Children must be `WizardStep`s and all props must be unique.
 */
const getPropsAsArray = (children: $TSFixMe) => {
  const stepNames: $TSFixMe = [];
  const displayNames: $TSFixMe = [];

  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child) && child.type === WizardStep) {
      // @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type 'unknown'.
      const { name, displayName } = child.props;

      if (stepNames.includes(name)) {
        throw new Error(`Found non-unique <WizardStep name="${name}">`);
      }
      stepNames.push(name);
      displayNames.push(displayName || name);
    }
  });

  return { stepNames, displayNames };
};

export type WizardProps = {
  children: React.ReactNode;
  initialInventory?: $TSFixMe;
  initialStep?: string;
  onFinish?: $TSFixMeFunction;
  onStepChange?: $TSFixMeFunction;
};

const Wizard = ({
  children,
  initialInventory = {},
  initialStep,
  onFinish,
  onStepChange,
}: WizardProps) => {
  const isDirty = useRef(false);
  const isFinished = useRef(false);
  const stateRef = useRef();

  // Create state and dispatch for the Wizard.
  const [state, dispatch] = useReducer(reducer, {
    inventory: initialInventory,
    initialStep,
    currentStep: null,
    stepNames: null,
    displayNames: null,
    inventoryCallbacks: [],
  });
  const previousStep = usePrevious(state.currentStep);

  stateRef.current = state;

  // Prompt user to confirm page unload if the inventory has ever changed from
  // the initial state. Removed when the Wizard unmounts.
  // TODO: Look into a global "dirty" state that prevents both SPA navigation
  // (via component library modal) and out-of-SPA navigation (beforeunload event).

  const returnValue = useDsTranslateMessage({
    id: 'mcds.wizard.return_value',
    defaultMessage: 'You have unfinished changes.',
  });

  useEffect(() => {
    const handleBeforeUnload = (event: $TSFixMe) => {
      if (isDirty.current && !isFinished.current) {
        event.preventDefault();
        event.returnValue = returnValue;
        return event.returnValue;
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Run `setInventory` callbacks.
  useUpdateEffect(() => {
    if (state.inventoryCallbacks.length > 0) {
      state.inventoryCallbacks.forEach((fn: $TSFixMe) => {
        fn(state);
      });
      dispatch({ type: 'RESET_SET_INVENTORY_CALLBACKS' });
    }
  }, [state]);

  // Run `onStepChange` callback.
  useUpdateEffect(() => {
    // Exit early if no `onStepChange` exists.
    if (!onStepChange) {
      return;
    }

    // Exit early if previous or next step are invalid.
    if (!previousStep || !state.currentStep) {
      return;
    }

    // Ensure step has changed before calling.
    if (previousStep !== state.currentStep) {
      onStepChange(
        previousStep,
        state.currentStep,
        state.inventory,
        state.stepNames,
      );
    }
  }, [state, previousStep, onStepChange]);

  /**
   * Not fully-fledged dispatch actions, callbacks are how the Wizard can
   * avoid async state issues.
   */
  const callbacks = useMemo(() => {
    return {
      finish: () => {
        if (isFinished.current) {
          // Defensive: Don't run `onFinish` more than once.
          return;
        }

        isFinished.current = true;
        if (onFinish) {
          // Use a ref to keep `state` out of the deps array.
          const currentState = stateRef.current;
          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          onFinish(currentState.inventory, currentState);
        }
      },
      getState: () => {
        return stateRef.current;
      },
    };
  }, [onFinish]);

  // Render!
  return (
    <WizardStateContext.Provider value={state}>
      <WizardDispatchContext.Provider value={dispatch}>
        <WizardCallbackContext.Provider value={callbacks}>
          {children}
        </WizardCallbackContext.Provider>
      </WizardDispatchContext.Provider>
    </WizardStateContext.Provider>
  );
};

// @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
const WizardStateContext = createContext();
// @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
const WizardDispatchContext = createContext();
// @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
const WizardCallbackContext = createContext();

/**
 * Low-level access to sending events to Wizard's reducer.
 */
const useWizardDispatch = () => useContext(WizardDispatchContext);

/**
 * Low-level access to accessing all of Wizard's state.
 */
const useWizardState = () => useContext(WizardStateContext);

/**
 * Low-level access to accessing Wizard's callbacks.
 */
const useWizardCallbacks = () => useContext(WizardCallbackContext);

/**
 * Access Wizard's shared state across steps.
 */
const useWizardInventory = () => (useWizardState as $TSFixMe)().inventory;

/**
 * Access Wizard's built-in event dispatchers.
 */
const useWizardActions = () => {
  const dispatch = useWizardDispatch();
  const callbacks = useWizardCallbacks();

  // Use a referentially stable object, just in case.
  const actions = useMemo(
    () => ({
      /**
       * The wizard navigates through WizardSteps.
       *
       * @param stepName The name of the WizardStep to navigate to.
       */
      navigate: (stepName: $TSFixMe) => {
        (dispatch as $TSFixMe)({ type: 'NAVIGATE', stepName });
      },

      /**
       * As the wizard navigates through steps, we may want to save some state.
       * This inventory is that state, shared between WizardSteps.
       *
       * @param inventoryFn A function that takes the current inventory and
       * returns a new one. Uses immer.js, so you can mutate the inventory!
       * @param onSuccess An optional function that runs after the new inventory
       * state gets set.
       */
      setInventory: (inventoryFn: $TSFixMe, onSuccess: $TSFixMe) => {
        (dispatch as $TSFixMe)({ type: 'SET_INVENTORY', inventoryFn });
        if (onSuccess) {
          (dispatch as $TSFixMe)({
            type: 'APPEND_SET_INVENTORY_CALLBACK',
            callback: onSuccess,
          });
        }
      },

      /**
       * Lets the Wizard safely exit.
       */
      finish: () => {
        (callbacks as $TSFixMe).finish();
      },
    }),
    [dispatch, callbacks],
  );

  return actions;
};

export type WizardStepsProps = {
  children?: React.ReactNode;
};

/**
 * Component that renders each step of a Wizard. Does not accept anything other
 * than a `WizardStep`. Notifies the parent Wizard of steps.
 */
const WizardSteps = ({ children }: WizardStepsProps) => {
  const dispatch = useWizardDispatch();
  const state = useWizardState();

  // Create and pass `isCurrentStep: true/false` to each WizardStep.
  children = React.Children.map(children, (child) => {
    if (!child) {
      return null;
    }
    const stepName = (child as $TSFixMe).props.name;
    const isCurrentStep = stepName === (state as $TSFixMe).currentStep;
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    return React.cloneElement(child, {
      isCurrentStep,
      key: stepName,
    });
  });

  const { stepNames, displayNames } = getPropsAsArray(children);

  useLayoutEffect(() => {
    const currentStep =
      (state as $TSFixMe).currentStep ??
      (state as $TSFixMe).initialStep ??
      stepNames[0];
    (dispatch as $TSFixMe)({
      type: 'UPDATE_STEPS',
      stepNames,
      displayNames,
      currentStep,
    });
    // Only call this effect when step names change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepNames.join('/'), displayNames.join('/')]);

  // This React.Fragment forces react-docgen to scrape this component
  // https://github.com/reactjs/react-docgen/issues/70
  return <React.Fragment>{children}</React.Fragment>;
};

export default Wizard;
export {
  useWizardActions,
  useWizardDispatch,
  useWizardInventory,
  useWizardState,
  WizardStep,
  WizardSteps,
};
