import React, { HTMLAttributes } from 'react';
import cx from 'classnames';
import Checkbox from '../../Checkbox';
import { TranslateSlat } from '../TranslateSlat';
import stylesheet from './SlatGeneric.less';

export type SlatGenericProps = {
  /** Pass the target ref on ClickArea to make the entire Slat clickable with preview of checkbox */
  checkboxRef?: React.Ref<HTMLInputElement>;
  /** Content within SlatGeneric */
  children: React.ReactNode;
  /** The current value of the selected SlatGeneric. Used with `onSelect` */
  isSelected?: boolean;
  /** Triggers when the SlatGeneric is selected. */
  onSelect?: (value: boolean) => void;
} & HTMLAttributes<HTMLElement>;

/* Individual SlatGeneric */
const SlatGeneric = React.forwardRef<HTMLLIElement, SlatGenericProps>(
  function SlatGeneric(
    { checkboxRef, children, onSelect, className, isSelected, ...props },
    forwardedRef,
  ) {
    const classList = cx(
      stylesheet.root,
      {
        [stylesheet.interactive]: !!onSelect,
      },
      className,
    );
    const { checkBoxLabelText } = TranslateSlat();
    return (
      <li className={classList} ref={forwardedRef} {...props}>
        <div className={stylesheet.wrapper}>
          {!!onSelect && (
            <span className={stylesheet.selection}>
              <Checkbox
                hideLabel
                label={checkBoxLabelText}
                value={isSelected || false}
                onChange={onSelect}
                ref={checkboxRef}
              />
            </span>
          )}
          {children}
        </div>
      </li>
    );
  },
);

export { SlatGeneric };
