import React from 'react';

import { WarningCircleIcon } from '@mc/wink-icons';
import { Text, ClusterLayout, TextButton } from '@mc/wink';
import useBreakpoints from '@mc/hooks/useBreakpoints';
import stylesheet from './SiteAlert.less';

export type SiteMessageProps = {
  action?: React.ReactNode;
  children: React.ReactNode;
  icon?: React.ReactElement;
  onClose?: $TSFixMeFunction;
  title: string;
};

const SiteMessage = React.forwardRef<$TSFixMe, SiteMessageProps>(
  function SiteMessage(
    { children, title, icon = <WarningCircleIcon />, action, onClose },
    forwardedRef,
  ) {
    const { isDesktop } = useBreakpoints();

    return (
      <ClusterLayout
        ref={forwardedRef}
        className={stylesheet.message}
        nowrap
        alignItems="flex-start"
      >
        {/* Icon */}
        {icon}

        <ClusterLayout
          justifyContent="space-between"
          nowrap={isDesktop}
          style={{ flex: 1 }}
        >
          {/* Copy */}
          <div
            className={stylesheet.description}
            // @ts-expect-error TS(2322) FIXME: Type 'false | 1' is not assignable to type 'Flex<s... Remove this comment to see the full error message
            style={{ flex: isDesktop && 1 }}
          >
            <Text appearance="small-bold">{title}</Text>
            <Text appearance="small-secondary">{children}</Text>
          </div>

          {/* Actions with Dismiss action */}
          <ClusterLayout nowrap={isDesktop}>
            {action}{' '}
            {onClose && <TextButton onClick={onClose}>Dismiss</TextButton>}
          </ClusterLayout>
        </ClusterLayout>
      </ClusterLayout>
    );
  },
);

export default SiteMessage;
