import React, { HTMLAttributes } from 'react';
import cx from 'classnames';
import useId from '@mc/hooks/useId';
import Text from '../Text';
import ButtonOrLink from '../ButtonOrLink';

import stylesheet from './ActionList.less';

export type ActionListItemProps = {
  /** `children` will not be supported in the long term.
   * Default to using the `label` prop. */
  children?: React.ReactNode;
  /** Secondary text for each action item */
  description?: string;
  /** Actions that are redirected as links */
  href?: string;
  /** Icon in front of the label */
  icon?: React.ReactNode;
  /** Icon at the end of the label */
  iconEnd?: React.ReactNode;
  /** Title of each action item */
  label?: string;
  /** Mouse click event */
  onClick?: () => void;
} & HTMLAttributes<HTMLAnchorElement | HTMLButtonElement>;

const ActionListItem = ({
  children,
  label,
  description,
  icon,
  iconEnd,
  onClick,
  href,
  ...rest
}: ActionListItemProps) => {
  const describeId = useId();

  return (
    <div
      className={cx(
        stylesheet.item,
        (rest as { disabled?: boolean }).disabled && stylesheet.disabled,
      )}
    >
      <ButtonOrLink
        onClick={onClick}
        className={stylesheet.label}
        href={href}
        aria-describedby={description ? describeId : undefined}
        {...rest}
      >
        {icon} <div>{label ? label : children}</div> {iconEnd}
      </ButtonOrLink>
      {description && (
        <Text
          appearance="medium-secondary"
          id={describeId}
          className={stylesheet.description}
        >
          {description}
        </Text>
      )}
    </div>
  );
};

export default ActionListItem;
