import React from 'react';
import cx from 'classnames';

import { CrossIcon } from '@mc/wink-icons';
import useId from '@mc/hooks/useId';
import { useDsTranslateMessage } from '@mc/wink/internationalization/useDsTranslateMessage';

import ButtonOrLink from '../ButtonOrLink';
import stylesheet from './Tag.less';

export type TagProps = {
  appearance?: 'dismissible';
  children: React.ReactNode;
  href?: string;
  onClick?: $TSFixMeFunction;
  size?: 'small' | 'large' | 'x-large';
};

const Tag = React.forwardRef<$TSFixMe, TagProps>(function Tag(
  // @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type 'Props... Remove this comment to see the full error message
  { appearance, className, children, href, onClick, size = 'large', ...props },
  ref,
) {
  const isInteractive = onClick || href;
  const TagType = isInteractive ? ButtonOrLink : 'span';
  const removeId = useId();

  // Translation for default text
  const removeTagText = useDsTranslateMessage({
    id: 'mcds.tag.remove_tag_text',
    defaultMessage: 'Remove Tag',
  });

  return (
    <TagType
      ref={ref}
      className={cx(
        stylesheet.root,
        appearance === 'dismissible' && stylesheet.dismissible,
        isInteractive && stylesheet.interactive,
        stylesheet[size],
        className,
      )}
      onClick={onClick}
      href={href}
      aria-describedby={appearance === 'dismissible' ? removeId : undefined}
      {...props}
    >
      {children}
      {appearance === 'dismissible' && (
        <React.Fragment>
          <CrossIcon className={stylesheet.crossIcon} />
          <span hidden id={removeId}>
            {removeTagText}
          </span>
        </React.Fragment>
      )}
    </TagType>
  );
});

export default Tag;
