import React, { MouseEventHandler } from 'react';
import cx from 'classnames';
import {
  CrossIcon,
  FeaturedIcon,
  InfoCircleIcon,
  NotAllowedIcon,
  TickCircleIcon,
  WarningCircleIcon,
} from '@mc/wink-icons';
import useBreakpoints from '@mc/hooks/useBreakpoints';
import { useDsTranslateMessage } from '@mc/wink/internationalization/useDsTranslateMessage';
import ClusterLayout from '../ClusterLayout';
import StackLayout from '../StackLayout';
import IconButton from '../IconButton';
import Portal from '../Portal';
import Text from '../Text';
import stylesheet from './FeedbackBlock.less';

type PortalWrapperProps = {
  children?: React.ReactNode;
  condition?: boolean;
};

const PortalWrapper = ({ condition, children }: PortalWrapperProps) => {
  // @ts-expect-error TS(2322) FIXME: Type '{ children: ReactNode; prependNode: true; }'... Remove this comment to see the full error message
  return condition ? children : <Portal prependNode>{children}</Portal>;
};

type FeedbackIconProps = {
  variant?: string;
};

const FeedbackIcon = ({ variant }: FeedbackIconProps) => {
  const icon =
    variant === 'success' ? (
      <TickCircleIcon />
    ) : variant === 'warning' ? (
      <WarningCircleIcon />
    ) : variant === 'error' ? (
      <NotAllowedIcon />
    ) : variant === 'info' ? (
      <FeaturedIcon />
    ) : (
      <InfoCircleIcon />
    );
  return icon;
};

type ContentLayoutProps = {
  children?: React.ReactNode;
};

const ContentLayout = ({ children }: ContentLayoutProps) => {
  const { isDesktop } = useBreakpoints();

  // Stack actions and content for responsive view and break at the same time.
  return isDesktop ? (
    <ClusterLayout
      gap={2}
      justifyContent="space-between"
      className={stylesheet.content}
      nowrap
    >
      {children}
    </ClusterLayout>
  ) : (
    <StackLayout gap={2} className={stylesheet.content}>
      {children}
    </StackLayout>
  );
};

export type FeedbackBlockProps = {
  /** Actions passed through with the TextButton or Link component */
  actions?: React.ReactNode;
  /** Copy that follows the bolded title of the feedback */
  children?: React.ReactNode;
  className?: string;
  /** Display inline if true. By default, the Feedback Block will append to the top of the page. */
  inline?: boolean;
  /** Function to dismiss FeedbackBlock */
  onClose?: MouseEventHandler<HTMLElement>;
  /** Bolded title */
  title: React.ReactNode;
  /** The different variants of FeedbackBlock */
  variant?: 'warning' | 'default' | 'success' | 'error';
};

const FeedbackBlock = React.forwardRef<HTMLDivElement, FeedbackBlockProps>(
  function FeedbackBlock(
    {
      actions,
      className,
      children,
      onClose,
      inline = false,
      title,
      variant = 'default',
      ...rest
    },
    forwardedRef,
  ) {
    const classList = cx(stylesheet.root, stylesheet[variant], className);
    const { isDesktop } = useBreakpoints();

    // Translation for default icon button label text
    const iconButtonLabelText = useDsTranslateMessage({
      id: 'mcds.feedback_block.icon_button_label_text',
      defaultMessage: 'Dismiss alert',
    });

    return (
      <PortalWrapper condition={inline}>
        <ClusterLayout
          nowrap
          className={classList}
          ref={forwardedRef}
          {...rest}
        >
          <span className={stylesheet.icon}>
            <FeedbackIcon variant={variant} />
          </span>
          <ContentLayout>
            <div className={stylesheet.copy}>
              <Text appearance="small-bold">{title}</Text>
              <Text as="span" appearance="small-secondary">
                {children}
              </Text>
            </div>
            <ClusterLayout
              nowrap={isDesktop}
              justifyContent={isDesktop ? 'flex-end' : 'flex-start'}
            >
              {actions}
            </ClusterLayout>
          </ContentLayout>
          {onClose && (
            <div>
              <IconButton
                label={iconButtonLabelText}
                icon={<CrossIcon />}
                onClick={onClose}
              />
            </div>
          )}
        </ClusterLayout>
      </PortalWrapper>
    );
  },
);

export default FeedbackBlock;
