import React, {
  useCallback,
  useEffect,
  useState,
  createContext,
  useRef,
} from 'react';
import cx from 'classnames';
import scrollIntoView from 'scroll-into-view-if-needed';
import useId from '@mc/hooks/useId';
import chainHandlers from '@mc/fn/chainHandlers';
import Popup from '../Popup';
import ButtonOrLink from '../ButtonOrLink';
import Option from './Option';
import stylesheet from './Listbox.less';
import { TranslateListbox } from './TranslateListbox';

const ListboxModeContext = createContext(false);

type ListboxOptgroupProps = {
  children?: React.ReactNode;
  disabled?: boolean;
  label: string;
};
const ListboxOptgroup = ({
  children,
  disabled,
  label,
}: ListboxOptgroupProps) => {
  const id = useId();
  return (
    <div
      role="group"
      aria-labelledby={id}
      aria-disabled={disabled}
      className={stylesheet.optgroupWrapper}
    >
      <div id={id} className={stylesheet.optgroup}>
        {label}
      </div>
      {children}
    </div>
  );
};

const defaultRenderSelectedValue = (
  selected: $TSFixMe,
  placeholder: $TSFixMe,
) => {
  return selected.length > 1
    ? `${selected.length} selected`
    : selected.length === 1
    ? selected.map((v: $TSFixMe) => v.children || v.value)
    : placeholder;
};

const defaultOptionsFilter = (value: $TSFixMe, option: $TSFixMe) => {
  return !!value && !option.toLowerCase().includes(value.toLowerCase());
};

export type ListboxProps = {
  'aria-labelledby'?: string;
  'aria-describedby'?: string;
  callToActionHref?: string;
  callToActionLabel?: string;
  callToActionOnClick?: $TSFixMeFunction;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  emptyOption?: string;
  isPopupFixed?: boolean;
  matchTargetWidth?: boolean;
  multiple?: boolean;
  onBlur?: $TSFixMeFunction;
  onChange: $TSFixMeFunction;
  onKeyDown?: $TSFixMeFunction;
  onOpen?: $TSFixMeFunction;
  onSearch?: $TSFixMeFunction;
  optionsFilter?: $TSFixMeFunction;
  placeholder?: React.ReactNode;
  renderSelectedValue?: $TSFixMeFunction;
  trigger?: React.ReactElement;
  value?: $TSFixMe;
};

/**
 * Listbox is a primitive component used to power other design system components.
 * It should *NEVER* be used directly in app code.
 */
const Listbox = React.forwardRef<$TSFixMe, ListboxProps>(function Listbox(
  {
    callToActionOnClick,
    callToActionHref,
    callToActionLabel,
    value,
    onChange,
    trigger: Trigger,
    emptyOption,
    placeholder,
    multiple = false,
    matchTargetWidth = false,
    renderSelectedValue = defaultRenderSelectedValue,
    className,
    onSearch = () => {},
    onOpen = () => {},
    optionsFilter = defaultOptionsFilter,
    children,
    disabled = false,
    isPopupFixed,
    ...props
  },
  forwardedRef,
) {
  // Translate default values
  const { noOptionsText, placeholderText } = TranslateListbox();
  emptyOption = emptyOption || noOptionsText;
  placeholder = placeholder || placeholderText;

  const [isExpanded, setIsExpanded] = useState(false);
  const [filter, setFilter] = useState();
  const [highlightedValue, setHighlightedValue] = useState(
    multiple && value && value.length > 0 ? value[0] : value,
  );
  const id = useId();
  const listboxRef = useRef();
  const containerRef = useRef();
  const ctaRef = useRef();
  const onOpenCalled = useRef();
  const allOptions: $TSFixMe = [];
  const enabledOptions: $TSFixMe = [];
  const cloned: $TSFixMe = [];

  const _onSearch = useCallback(onSearch, []);

  useEffect(() => {
    if (!onOpenCalled.current && isExpanded) {
      onOpen();
      // @ts-expect-error TS(2322) FIXME: Type 'true' is not assignable to type 'undefined'.
      onOpenCalled.current = true;
    }
    if (!isExpanded) {
      // @ts-expect-error TS(2322) FIXME: Type 'false' is not assignable to type 'undefined'... Remove this comment to see the full error message
      onOpenCalled.current = false;
    }
  }, [isExpanded, onOpen]);

  useEffect(() => {
    if (!disabled) {
      if (filter && (filter as $TSFixMe).length > 0 && !isExpanded) {
        setIsExpanded(true);
      }
      if (_onSearch) {
        _onSearch(filter);
      }
    } else if (isExpanded) {
      setIsExpanded(false);
    }
  }, [filter, isExpanded, disabled, _onSearch]);
  const handleSelect = (selectedValue: $TSFixMe) => {
    if (multiple) {
      // Preserve the highlighted value after the selection
      setHighlightedValue(selectedValue);

      if (!value) {
        onChange([selectedValue]);
      } else {
        const newValues = allOptions
          // @ts-expect-error TS(7006) FIXME: Parameter 'option' implicitly has an 'any' type.
          .filter((option) => {
            return (
              // adds and removes the clicked on child
              (option.value === selectedValue &&
                !value.includes(selectedValue)) ||
              // keep elements that already been selected
              (option.value !== selectedValue && option.isSelected)
            );
          })
          // @ts-expect-error TS(7006) FIXME: Parameter 'option' implicitly has an 'any' type.
          .map((option) => option.value);
        onChange(newValues);
      }
    } else {
      onChange(selectedValue);
      setIsExpanded(false);
    }

    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
    setFilter();
  };

  // This loop determines a few things in a single iteration of each option:
  //
  // 1. create array to manage traversing/navigating the options
  // 2. the selected item (if one is selected)
  // 3. the highlighted item (used when navigating the options with keyboard)
  // 4. enabled/disabled logic
  // 5. clone children for display
  let index = 0;
  React.Children.forEach(children, (child) => {
    if (!child) {
      return;
    }
    const options =
      (child as $TSFixMe).type === 'optgroup'
        ? React.Children.toArray((child as $TSFixMe).props.children)
        : [child];
    let hasUnfilteredOptions =
      (child as $TSFixMe).type !== 'optgroup' || !filter;
    const currentIndex = index;
    // 1. create array to manage traversing/navigating the options
    options.forEach((option) => {
      // 2. the selected item
      const isSelected = multiple
        ? !value
          ? false
          : value.includes((option as $TSFixMe).props.value)
        : (option as $TSFixMe).props.value === value
        ? true
        : // Do not output aria-selected="false" for single-select comboboxes.
          undefined;
      let filterText;
      if ((option as $TSFixMe).props.label) {
        filterText = (option as $TSFixMe).props.label;
      } else if (typeof (option as $TSFixMe).props.children === 'string') {
        filterText = (option as $TSFixMe).props.children;
      } else if ((option as $TSFixMe).type !== ListboxOptgroup) {
        throw new Error(
          'Options of a searchable listbox must have string children or a label prop',
        );
      }
      const isFiltered = optionsFilter(filter, filterText);
      // 3. the highlighted item
      const isHighlighted =
        !isFiltered && (option as $TSFixMe).props.value === highlightedValue;
      // 4. enabled/disabled logic
      const isDisabled =
        ((child as $TSFixMe).type === 'optgroup' &&
          (child as $TSFixMe).props.disabled) ||
        (option as $TSFixMe).props.disabled;
      const isEnabled =
        (option as $TSFixMe).type !== ListboxOptgroup &&
        !isDisabled &&
        !isFiltered;
      if (!isFiltered && (child as $TSFixMe).type === 'optgroup') {
        hasUnfilteredOptions = true;
      }
      const optionProps = {
        ...(option as $TSFixMe).props,
        key: (option as $TSFixMe).props.value,
        id: id + '-' + (option as $TSFixMe).props.value,
        disabled: isDisabled,
        isSelected,
        isHighlighted,
        isFiltered,
        onHighlight: setHighlightedValue,
        onClick: () => {
          if (isEnabled) {
            handleSelect((option as $TSFixMe).props.value);
            setIsExpanded(multiple);
          }
        },
      };
      allOptions.push(optionProps);
      if (isEnabled) {
        enabledOptions.push(optionProps);
      }
      index += 1;
    });
    // 5. Clone children for display
    if ((child as $TSFixMe).type === 'optgroup') {
      if (hasUnfilteredOptions) {
        const {
          children: childChildren,
          ...childProps
        } = (child as $TSFixMe).props;
        cloned.push(
          <ListboxOptgroup {...childProps} key={childProps.label}>
            {React.Children.map(childChildren, (childChild, childIndex) => {
              return React.cloneElement(
                childChild,
                allOptions[currentIndex + childIndex],
              );
            })}
          </ListboxOptgroup>,
        );
      }
    } else {
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      cloned.push(React.cloneElement(child, allOptions[currentIndex]));
    }
  });

  const handleKeyDown = (event: $TSFixMe) => {
    if (disabled) {
      return;
    }

    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        if (isExpanded) {
          // If using filtered options, select either the highlighted value or the originally selected value
          if (filter) {
            const selectedByFilter =
              enabledOptions.find(
                // @ts-expect-error TS(7006) FIXME: Parameter 'option' implicitly has an 'any' type.
                (option) => option.value === highlightedValue,
              ) || enabledOptions[0];

            if (selectedByFilter) {
              handleSelect(selectedByFilter.value);
            }
          } else {
            handleSelect(highlightedValue);
          }
        } else {
          setIsExpanded(true);
        }
        break;

      case 'Escape':
        if (isExpanded) {
          event.preventDefault();
          setIsExpanded(false);
        }
        break;

      case 'ArrowUp':
        event.preventDefault();
        if (isExpanded) {
          const highlightedIndex = enabledOptions.findIndex(
            // @ts-expect-error TS(7006) FIXME: Parameter 'option' implicitly has an 'any' type.
            (option) => option.isHighlighted,
          );
          const prev =
            (highlightedIndex - 1 + enabledOptions.length) %
            enabledOptions.length;
          setHighlightedValue(enabledOptions[prev].value);
        } else {
          setIsExpanded(true);
        }
        break;

      case 'ArrowDown':
        event.preventDefault();
        if (isExpanded) {
          const highlightedIndex = enabledOptions.findIndex(
            // @ts-expect-error TS(7006) FIXME: Parameter 'option' implicitly has an 'any' type.
            (option) => option.isHighlighted,
          );
          const next = (highlightedIndex + 1) % enabledOptions.length;
          setHighlightedValue(enabledOptions[next].value);
        } else {
          setIsExpanded(true);
        }
        break;

      case 'Home':
        if (isExpanded) {
          event.preventDefault();
          setHighlightedValue(enabledOptions[0].value);
        }
        break;

      case 'End':
        if (isExpanded) {
          event.preventDefault();
          setHighlightedValue(enabledOptions[enabledOptions.length - 1].value);
        }
        break;

      // Emulating native select behavior
      // Options being open prevent tabs from changing focus
      case 'Tab':
        if (isExpanded && filter === undefined) {
          if (callToActionLabel && !event.shiftKey) {
            event.preventDefault();
            // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
            ctaRef.current.focus();
            setIsExpanded(true);
          }
        }
        break;

      default:
        break;
    }
  };

  const handleCtaKeyDown = (event: $TSFixMe) => {
    if (event.key === 'Tab' && !event.shiftKey) {
      setIsExpanded(false);
    }
  };

  useEffect(() => {
    if (isExpanded && listboxRef.current) {
      const firstSelectedValue = (listboxRef.current as $TSFixMe).querySelector(
        '[aria-selected=true]',
      );
      if (firstSelectedValue) {
        scrollIntoView(firstSelectedValue, {
          block: 'nearest',
          scrollMode: 'if-needed',
          boundary: listboxRef.current,
        });
      }
    }
  }, [isExpanded]);

  return (
    // @ts-expect-error TS(2322) FIXME: Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
    <div className={cx(stylesheet.container, className)} ref={containerRef}>
      {/* @ts-expect-error TS(2604) FIXME: JSX element type 'Trigger' does not have any const... Remove this comment to see the full error message */}
      <Trigger
        {...props}
        disabled={disabled}
        isExpanded={isExpanded}
        placeholder={placeholder}
        renderSelectedValue={renderSelectedValue}
        filter={filter}
        onFilterChange={setFilter}
        options={enabledOptions}
        // @ts-expect-error TS(7006) FIXME: Parameter 'option' implicitly has an 'any' type.
        selected={enabledOptions.filter((option) => option.isSelected)}
        id={id + '-trigger'}
        role="combobox"
        aria-autocomplete={filter ? 'list' : 'none'}
        aria-haspopup="listbox"
        aria-controls={isExpanded ? id : undefined}
        aria-expanded={isExpanded}
        aria-activedescendant={
          isExpanded ? `${id}-${highlightedValue}` : undefined
        }
        onHighlight={setHighlightedValue}
        onSelect={handleSelect}
        onToggle={() => setIsExpanded((prev) => (disabled ? false : !prev))}
        // @ts-expect-error TS(2345) FIXME: Argument of type '$TSFixMeFunction | undefined' is... Remove this comment to see the full error message
        onKeyDown={chainHandlers(props.onKeyDown, handleKeyDown)}
        // @ts-expect-error TS(2345) FIXME: Argument of type '$TSFixMeFunction | undefined' is... Remove this comment to see the full error message
        onBlur={chainHandlers(props.onBlur, () => setIsExpanded(false))}
        ref={forwardedRef}
      />
      {isExpanded && (
        <Popup
          matchTargetWidth={matchTargetWidth}
          targetRef={containerRef}
          // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; matchTargetWidth: boole... Remove this comment to see the full error message
          className={stylesheet.popup}
          fixed={isPopupFixed}
        >
          <div
            className={stylesheet.listbox}
            // preventDefault stops the trigger from losing focus. It also stops
            // an optgroup from collapsing the listbox.
            onMouseDown={(event) => {
              event.preventDefault();
            }}
          >
            <div
              className={stylesheet.options}
              // @ts-expect-error TS(2322) FIXME: Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
              ref={listboxRef}
              role="listbox"
              id={id}
            >
              <ListboxModeContext.Provider value={true}>
                {!!filter && enabledOptions.length === 0 ? (
                  <Option disabled>{emptyOption}</Option>
                ) : (
                  cloned
                )}
              </ListboxModeContext.Provider>
            </div>
            {callToActionLabel && (
              <div className={stylesheet.callToAction}>
                <ButtonOrLink
                  // @ts-expect-error TS(2322) FIXME: Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
                  ref={ctaRef}
                  href={callToActionHref}
                  onClick={callToActionOnClick}
                  onKeyDown={handleCtaKeyDown}
                >
                  {callToActionLabel}
                </ButtonOrLink>
              </div>
            )}
          </div>
        </Popup>
      )}
    </div>
  );
});

export { ListboxModeContext };

export default Listbox;
