import React, { useState, PropsWithChildren } from 'react';
import { MenuDownIcon } from '@mc/wink-icons';
import Animate from '../Animate';
import stylesheet from './Disclosure.css';

export type DisclosureProps = PropsWithChildren<{
  children: React.ReactNode;
  heading: string;
}>;

const Disclosure = React.forwardRef<HTMLDivElement, DisclosureProps>(
  function Disclosure({ children, heading }, forwardedRef) {
    const [isExpanded, setIsExpanded] = useState(false);
    return (
      <div className={stylesheet.root} ref={forwardedRef}>
        <button
          aria-expanded={isExpanded}
          type="button"
          className={stylesheet.heading}
          onClick={() => setIsExpanded((value) => !value)}
        >
          <MenuDownIcon />
          {heading}
        </button>
        <Animate
          animation="slide"
          easing="expressive"
          toggle={isExpanded}
          className={stylesheet.body}
        >
          {children}
        </Animate>
      </div>
    );
  },
);

export default Disclosure;
