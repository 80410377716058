import React, { useRef, useLayoutEffect } from 'react';
import cx from 'classnames';
import scrollIntoView from 'scroll-into-view-if-needed';

import { TickIcon } from '@mc/wink-icons';
import stylesheet from './ComboboxOption.less';

export type ComboboxOptionProps = {
  'aria-selected'?: boolean;
  children?: React.ReactNode;
  isHighlighted?: boolean;
  onSelect?: $TSFixMeFunction;
  setActive?: $TSFixMeFunction;
  value: string;
};

const ComboboxOption = ({
  'aria-selected': ariaSelected,
  children,
  value,
  isHighlighted = false,
  setActive,
  onSelect,
  ...props
}: ComboboxOptionProps) => {
  const ref = useRef();
  useLayoutEffect(() => {
    if (isHighlighted && ref.current) {
      scrollIntoView(ref.current, {
        block: 'nearest',
        scrollMode: 'if-needed',
      });
    }
  }, [isHighlighted]);

  return (
    <li
      // @ts-expect-error TS(2322) FIXME: Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
      ref={ref}
      className={cx(stylesheet.option, {
        [stylesheet.isHighlighted]: isHighlighted,
      })}
      role="option"
      aria-selected={ariaSelected}
      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      onClick={() => onSelect(value)}
      // If options appear under the mouse, onMouseEnter will fire, but
      // onMouseMove will not. A subtle but effective usability improvement.
      onMouseMove={() => {
        // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        setActive();
      }}
      {...props}
    >
      {children || value}
      {ariaSelected && <TickIcon className={stylesheet.selectedIcon} />}
    </li>
  );
};

export default ComboboxOption;
