import React from 'react';
import cx from 'classnames';

import valueTrend from './valueTrend';

import stylesheet from './DataWell.less';

export type DataWellItemProps = {
  label: string | React.ReactNode;
  miscText?: string;
  trend?: 'positive' | 'negative' | 'neutral';
  value: string | React.ReactNode;
};

const DataWellItem = React.forwardRef<$TSFixMe, DataWellItemProps>(
  function DataWell({ value, label, miscText, trend }, forwardedRef) {
    return (
      <div
        className={cx(stylesheet.rootItem, {
          [stylesheet.hasMiscText]: miscText,
        })}
        ref={forwardedRef}
      >
        <div className={stylesheet.valueContainer}>
          <div className={stylesheet.value}>{value}</div>
          {miscText && (
            <div className={stylesheet.miscTextContainer}>
              <span
                className={cx(stylesheet.miscText, {
                  // @ts-expect-error TS(2538) FIXME: Type 'null' cannot be used as an index type.
                  [stylesheet[trend ?? valueTrend(miscText)]]:
                    trend ?? valueTrend(miscText),
                })}
              >
                {miscText}
              </span>
            </div>
          )}
        </div>
        <div className={stylesheet.label}>{label}</div>
      </div>
    );
  },
);

export type DataWellProps = {
  align?: 'start' | 'center';
  appearance?: 'default' | 'none';
  children?: React.ReactNode;
  className?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  wrapText?: boolean;
};

const DataWell = React.forwardRef<$TSFixMe, DataWellProps>(
  function DataWellGroup(
    {
      align = 'center',
      appearance = 'default',
      children,
      size = 'md',
      className,
      wrapText = false,
    },
    forwardedRef,
  ) {
    return (
      <div
        className={cx(
          [
            stylesheet.root,
            stylesheet[size],
            stylesheet[align],
            stylesheet[appearance],
            className,
          ],
          {
            [stylesheet.wrapText]: wrapText,
          },
        )}
        ref={forwardedRef}
      >
        {children}
      </div>
    );
  },
);

export default DataWell;
export { DataWellItem };
