import React, { useContext, createContext } from 'react';
import cx from 'classnames';
import ClassicPortal from '@mc/components/Portal';
import useId from '@mc/hooks/useId';
import { useInteropContext } from '../WinkStyles';
import Theme, { useThemeContext, useSubbrandContext } from '../Theme';

// @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
export const PortalStackContext = createContext();

export type PortalProps = {
  children?: React.ReactNode;
};

/**
 * Wraps the Portal component's children with an interop wrapper, if needed.
 */
const Portal = ({ children, ...props }: PortalProps) => {
  const portalId = useId();
  const oldStack = useContext(PortalStackContext);
  const newStack = oldStack ? `${portalId}:${oldStack}` : portalId;

  const interopContext = useInteropContext();
  const theme = useThemeContext();
  const subbrand = useSubbrandContext();

  return (
    <PortalStackContext.Provider value={newStack}>
      <ClassicPortal portalStack={newStack} {...props}>
        {/* @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type '"light" ... Remove this comment to see the full error message */}
        <Theme theme={theme} subbrand={cx(interopContext, subbrand)}>
          {children}
        </Theme>
      </ClassicPortal>
    </PortalStackContext.Provider>
  );
};

export default Portal;
