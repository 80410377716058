import React from 'react';

import Text from '../Text';
import SwitcherLayout from '../SwitcherLayout';
import ContentArea from '../ContentArea';

import stylesheet from './PullQuote.less';

export type PullQuoteProps = {
  callToAction: React.ReactNode;
  eyebrow: string;
  media?: React.ReactNode;
  quote: string;
  secondary: string;
};

const PullQuote = React.forwardRef<$TSFixMe, PullQuoteProps>(function PullQuote(
  { eyebrow, quote, secondary, media, callToAction },
  forwardedRef,
) {
  return (
    <ContentArea className={stylesheet.root} ref={forwardedRef}>
      <SwitcherLayout>
        <div
          style={{
            flexGrow: 3,
          }}
        >
          <Text appearance="small-bold" className={stylesheet.eyebrow}>
            {eyebrow}
          </Text>
          <figure className={stylesheet.quoteContainer}>
            <blockquote>
              <Text appearance="heading-2">“{quote}”</Text>
            </blockquote>

            <figcaption className={stylesheet.secondaryContainer}>
              <Text appearance="small-secondary">{secondary}</Text>
            </figcaption>
          </figure>
          <div className={stylesheet.callToAction}>{callToAction}</div>
        </div>
        {media && <div className={stylesheet.mediaContainer}>{media}</div>}
      </SwitcherLayout>
    </ContentArea>
  );
});

export default PullQuote;
