import React, { MouseEventHandler } from 'react';
import cx from 'classnames';

import { ArrowLeftIcon } from '@mc/wink-icons';
import { mcdsFlagCheck } from '@mc/wink/helpers/utils-ts';
import ClusterLayout from '../ClusterLayout';
import Heading from '../Heading';
import IconButton from '../IconButton';
import StackLayout from '../StackLayout';
import { WizardBreadcrumbs } from '../Wizard';
import ContentArea from '../ContentArea';
import { TranslatePage } from '../Page/TranslatePage';
import stylesheet from './PageHeader.css';

export type PageHeaderProps = {
  /** Should be an instance of <PageHeaderActions> */
  actions?: React.ReactNode;
  /** An instance of WizardBreadcrumbs */
  breadcrumbs?: React.ReactNode;
  /** Any custom content that will appear in the center of the bar. Please note that you will be responsible for handling any
   * mobile styles for these children.
   */
  children?: React.ReactNode;
  /** Custom className for header ContentArea */
  className?: string;
  /**
   * Enabling focus mode to true will set the left padding of the header to exactly 100px,
   *  which is the appropriate space needed for the Freddie logo provided by the app's left nav.
   * */
  focusMode?: boolean;
  /** Setting this to true will apply text-overflow: ellipsis to the title and prevent wrapping. */
  isTitleTruncated?: boolean;
  /** Allows for a select input to be placed adjacent to the header title. Should be
   * of type PageHeaderSelect
   */
  menu?: React.ReactNode;
  /** Function that runs when clicking the referrer link */
  onReferrerClick?: MouseEventHandler<HTMLButtonElement> &
    MouseEventHandler<HTMLAnchorElement>;
  /** *** Deprecated *** Please use breadcrumbs for page navigation. *** Deprecated ***  */
  referrer?: string;
  /** Custom icon to be used for referrer back button. */
  referrerIcon?: React.ReactNode;
  /** Most pages should use standard (which is the default). Use wide for full width sizes. Use narrow for a more compact page header. */
  size?: 'standard' | 'narrow' | 'wide';
  /** The main text of the Header. The string or node passed in here will be wrapped with a Heading component. */
  title: React.ReactNode;
};

/**
 * `PageHeader` should always be wrapped by `Page` to create an isolated stacking context which does not interfere with other z-indexes.
 */
const PageHeader = React.forwardRef<HTMLElement, PageHeaderProps>(
  function PageHeader(
    {
      children,
      title,
      actions,
      referrer,
      menu,
      referrerIcon = null,
      onReferrerClick = undefined,
      breadcrumbs = null,
      focusMode = false,
      size = 'standard',
      className,
      isTitleTruncated = false,
      ...props
    },
    forwardedRef,
  ) {
    // Translate default text
    const { backText } = TranslatePage();
    const isUsingWizardBreadcrumbs =
      breadcrumbs &&
      (breadcrumbs as React.ReactElement).type === WizardBreadcrumbs;
    return (
      <div {...props} className={stylesheet.container}>
        <ContentArea
          size={size}
          as="header"
          ref={forwardedRef}
          className={cx(
            stylesheet.header,
            focusMode && stylesheet.focusMode,
            className,
          )}
        >
          <ClusterLayout
            nowrap
            className={cx(
              isTitleTruncated && stylesheet.titleOverflowEllipsisCluster,
            )}
          >
            {referrer && (
              <div>
                <IconButton
                  label={backText}
                  onClick={onReferrerClick}
                  href={referrer}
                  icon={referrerIcon || <ArrowLeftIcon />}
                />
              </div>
            )}
            <StackLayout
              style={{
                flexDirection: isUsingWizardBreadcrumbs
                  ? 'column-reverse'
                  : 'column',
              }}
              gap={1}
              className={cx(
                isTitleTruncated && stylesheet.titleOverflowEllipsisStack,
              )}
            >
              {breadcrumbs}
              <div className={stylesheet.titleMenuContainer}>
                <Heading
                  level={1}
                  appearance={
                    breadcrumbs
                      ? mcdsFlagCheck('xp_project_runway_design_foundation')
                        ? 'heading-6'
                        : 'heading-4'
                      : mcdsFlagCheck('xp_project_runway_design_foundation')
                      ? 'heading-5'
                      : 'heading-3'
                  }
                  className={cx(
                    isTitleTruncated && stylesheet.titleOverflowEllipsisHeading,
                  )}
                >
                  {title}
                </Heading>
                {menu}
              </div>
            </StackLayout>
          </ClusterLayout>

          <div>{children}</div>

          {actions && (
            <ClusterLayout className={stylesheet.actions}>
              {actions}
            </ClusterLayout>
          )}
        </ContentArea>
      </div>
    );
  },
);

export default PageHeader;
