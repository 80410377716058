import React, { useEffect, HTMLAttributes } from 'react';
import cx from 'classnames';
import useId from '@mc/hooks/useId';
import {
  ariaDescribedByIds,
  ariaLabelledByIds,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  formatError,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ERROR_MUST_PROVIDE_LABEL,
} from '../utils';
import stylesheet from './Checkbox.css';

export type CheckboxProps = {
  /** Pass an element's ID to include its text content as part of this component's accessible name. */
  'aria-labelledby'?: string;
  /** Will provide the checkbox with a description below the label. */
  children?: React.ReactNode;
  /** Makes the input unusable and un-clickable. */
  disabled?: boolean;
  /** Will show in place of help text if defined also applies invalid style treatment. */
  error?: string;
  /** Visually hides the label provided by the `label` prop. */
  hideLabel?: boolean;
  /** Id passed to the input, this is automatically created and not required */
  id?: string;
  /** Whether properties owned by the box are partially checked. */
  indeterminate?: boolean;
  /** The label of the checkbox. */
  label?: React.ReactNode;
  /** Required to enforce that this component should always be controlled. */
  onChange: (value: boolean) => void;
  /** Required to enforce that this component should always be controlled. */
  value: boolean;
} & HTMLAttributes<HTMLInputElement>;

/** Checkboxes are a form element used to make any number of selections from a list. */
const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  function Checkbox(
    {
      'aria-labelledby': ariaLabelledBy,
      children,
      className,
      error,
      disabled = false,
      hideLabel = false,
      label,
      onChange,
      value,
      indeterminate = false,
      ...props
    },
    forwardedRef,
  ) {
    useEffect(() => {
      if (!label && !ariaLabelledBy && __DEV__) {
        throw new Error(formatError(ERROR_MUST_PROVIDE_LABEL, 'Checkbox'));
      }
    }, [label, ariaLabelledBy]);

    const autoId = useId();
    const labelId = useId();
    const descriptionId = useId();
    const errorId = useId();
    const describedBy = ariaDescribedByIds(
      children ? descriptionId : '',
      error ? errorId : '',
    );
    const id = props.id || autoId;

    // indeterminate cannot be set directly as an HTML attribute and must be set via JS
    useEffect(() => {
      const checkbox = document.getElementById(id) as HTMLInputElement;
      if (checkbox) {
        checkbox.setAttribute('indeterminate', indeterminate.toString());
      }
    }, [id, indeterminate]);

    return (
      <div
        className={cx(stylesheet.root, className, {
          [stylesheet.error]: !!error,
        })}
      >
        <input
          className={cx(stylesheet.input, {
            [stylesheet.noMargin]: (hideLabel || !label) && !describedBy,
          })}
          id={id}
          type="checkbox"
          checked={value}
          disabled={disabled}
          onChange={(event) => onChange(event.target.checked)}
          // We need to handle three cases:
          //
          // 1. Only pass a `label`. Since we're using a native label element,
          //    pointing `aria-labelledby` to the existing label element is
          //    unnecessary.
          // 2. Only pass an `aria-labelledby`. We don't render a label element.
          // 3. Pass both a `label` and `aria-labelledby`. We refer to both in the
          //    `aria-labelledby` attribute.
          aria-labelledby={ariaLabelledByIds(
            ariaLabelledBy ?? '',
            ariaLabelledBy && label ? labelId : '',
          )}
          aria-describedby={describedBy}
          aria-checked={indeterminate ? 'mixed' : value ? 'true' : 'false'}
          ref={forwardedRef}
          {...props}
        />
        <div className={stylesheet.text}>
          {label && (
            <label
              id={labelId}
              htmlFor={id}
              className={cx(hideLabel && 'wink-visually-hidden', {
                [stylesheet.labelDisabled]: disabled,
              })}
            >
              {label}
            </label>
          )}
          {children && (
            <div id={descriptionId} className={stylesheet.description}>
              {children}
            </div>
          )}
          {error && (
            <div id={errorId} className={stylesheet.errorMessage}>
              {error}
            </div>
          )}
        </div>
      </div>
    );
  },
);

export default Checkbox;
