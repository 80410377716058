import React, {
  Fragment,
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
  createContext,
} from 'react';
import cx from 'classnames';
import tabbable from 'tabbable';

import useId from '@mc/hooks/useId';
import useOutsideClick from '@mc/hooks/useOutsideClick';
import chainHandlers from '@mc/fn/chainHandlers';
import Animate from '../Animate';
import Popup from '../Popup';

import stylesheet from './Popover.less';

const noop = () => {};

// @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
export const PopoverContext = createContext();

/** Focus into Popover when opened */
function useInnerFocus(
  triggerRef: $TSFixMe,
  popoverRef: $TSFixMe,
  isVisible: $TSFixMe,
) {
  const isMountedRef = useRef();

  useEffect(() => {
    // @ts-expect-error TS(2322) FIXME: Type 'true' is not assignable to type 'undefined'.
    isMountedRef.current = true;

    return () => {
      // @ts-expect-error TS(2322) FIXME: Type 'false' is not assignable to type 'undefined'... Remove this comment to see the full error message
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      const trigger = triggerRef.current;
      const popover = popoverRef.current;
      const tabbables = popover ? tabbable(popover) : [];
      if (tabbables.length) {
        tabbables[0].focus();
      }

      return () => {
        if (isMountedRef.current) {
          trigger.focus();
        }
      };
    }
  }, [isVisible, popoverRef, triggerRef]);
}

type PopoverProps = {
  arrowClassName?: string;
  children: React.ReactNode;
  className?: string;
  description: string;
  direction?:
    | 'auto'
    | 'auto-start'
    | 'auto-end'
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'left'
    | 'left-start'
    | 'left-end';
  forceVisible?: boolean;
  hasArrow?: boolean;
  label?: string;
  offset?: number;
  onOpen?: $TSFixMeFunction;
  onRequestClose?: $TSFixMeFunction;
  trigger: React.ReactNode;
};

/**
 * Activating the Popover trigger opens an inline, interactive popup. Popovers
 * have a close button, trap focus, and are dismissible using the close button
 * or via outside click.
 *
 * A close button for assistive technology is required inside the Popover.
 *
 * The trigger must support ref forwarding. Without it, the Popover will not
 * know how to position itself.
 */
function Popover({
  arrowClassName,
  children,
  className,
  direction = 'bottom',
  label,
  description,
  hasArrow = false,
  forceVisible = false,
  trigger,
  offset = 12,
  onOpen = noop,
  onRequestClose = noop,
  ...props
}: PopoverProps) {
  const labelId = useId();
  const descriptionId = useId();
  const triggerRef = useRef();
  const popoverRef = useRef();
  const [isVisible, setIsVisible] = useState(false);

  const open = useCallback(() => {
    setIsVisible(true);
    onOpen();
  }, [onOpen]);

  const close = useCallback(() => {
    setIsVisible(false);
    onRequestClose();
  }, [onRequestClose]);
  const isOpen = forceVisible || isVisible;

  const contextValue = useMemo(
    () => ({
      isOpen,
      open,
      close,
    }),
    [open, close, isOpen],
  );

  useOutsideClick(popoverRef, close);
  useInnerFocus(triggerRef, popoverRef, isVisible);

  React.Children.only(trigger);

  return (
    <PopoverContext.Provider value={contextValue}>
      {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
      {React.cloneElement(trigger, {
        onClick: chainHandlers((trigger as $TSFixMe).props.onClick, open),
        'aria-expanded': isOpen.toString(),
        ref: triggerRef,
      })}
      <Animate
        // @ts-expect-error TS(2322) FIXME: Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
        ref={popoverRef}
        component={Popup}
        toggle={isOpen}
        // Popup props
        arrow={
          hasArrow ? (
            <div className={cx(stylesheet.arrowRoot, arrowClassName)} />
          ) : (
            <Fragment />
          )
        }
        placement={direction}
        targetRef={triggerRef}
        offset={offset}
        // Underlying component props
        className={cx(stylesheet.root, className)}
        {...props}
      >
        {label && (
          <span id={labelId} className="wink-visually-hidden">
            {label}
          </span>
        )}
        {description && (
          <span id={descriptionId} className="wink-visually-hidden">
            {description}
          </span>
        )}
        <div
          aria-labelledby={label && labelId}
          aria-describedby={description && descriptionId}
          role="dialog"
        >
          {children}
        </div>
      </Animate>
    </PopoverContext.Provider>
  );
}

export default Popover;
