import React, { useState, useRef } from 'react';
import cx from 'classnames';
import useId from '@mc/hooks/useId';
import chainHandlers from '@mc/fn/chainHandlers';
import useDeviceContext from '@mc/editing/hooks/useDeviceContext';
import Animate from '../Animate';
import Popup from '../Popup';
import stylesheet from './Tooltip.less';

export type TooltipProps = {
  ariaStrategy?: 'label' | 'description';
  'aria-label'?: string;
  children: React.ReactNode;
  className?: string;
  direction?:
    | 'auto'
    | 'auto-start'
    | 'auto-end'
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'left'
    | 'left-start'
    | 'left-end';
  forceVisible?: boolean;
  hideTimeout?: number;
  label: React.ReactNode;
  showTimeout?: number;
};

/**
 * Tooltips provide contextual, helpful, but nonessential secondary information
 * upon hover or keyboard focus.
 *
 * Its child must support ref forwarding. Without it, the Tooltip will not know
 * how to position itself.
 */
function Tooltip({
  ariaStrategy,
  children,
  className,
  label,
  'aria-label': ariaLabel,
  direction = 'bottom',
  forceVisible = false,
  showTimeout,
  hideTimeout,
  ...props
}: TooltipProps) {
  const [isVisible, setIsVisible] = useState(false);
  const triggerRef = useRef();
  const delayedUpdate = useRef(false);
  const id = useId();
  const { isMobileWeb, isMobileNative } = useDeviceContext();

  React.Children.only(children);

  const show = () => {
    if (delayedUpdate.current) {
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      clearTimeout(delayedUpdate.current);
    }

    if (showTimeout) {
      // @ts-expect-error TS(2322) FIXME: Type 'Timeout' is not assignable to type 'boolean'... Remove this comment to see the full error message
      delayedUpdate.current = setTimeout(() => {
        setIsVisible(true);
      }, showTimeout);
    } else {
      setIsVisible(true);
    }
  };
  const hide = () => {
    if (delayedUpdate.current) {
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      clearTimeout(delayedUpdate.current);
    }

    if (hideTimeout) {
      // @ts-expect-error TS(2322) FIXME: Type 'Timeout' is not assignable to type 'boolean'... Remove this comment to see the full error message
      delayedUpdate.current = setTimeout(() => {
        setIsVisible(false);
      }, hideTimeout);
    } else {
      setIsVisible(false);
    }
  };
  const shouldShow =
    (forceVisible || isVisible) && !isMobileNative && !isMobileWeb;

  return (
    <React.Fragment>
      {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
      {React.cloneElement(children, {
        onFocus: chainHandlers((children as $TSFixMe).props.onFocus, show),
        onBlur: chainHandlers((children as $TSFixMe).props.onBlur, hide),
        onMouseEnter: chainHandlers(
          (children as $TSFixMe).props.onMouseEnter,
          show,
        ),
        onMouseLeave: chainHandlers(
          (children as $TSFixMe).props.onMouseLeave,
          hide,
        ),
        onClick: chainHandlers((children as $TSFixMe).props.onClick, hide),
        'aria-describedby':
          (children as $TSFixMe).props['aria-describedby'] ||
          (ariaStrategy === 'description' ? id : undefined),
        'aria-labelledby':
          (children as $TSFixMe).props['aria-labelledby'] ||
          (ariaStrategy === 'label' ? id : undefined),
        ref: triggerRef,
      })}
      <Animate
        component={Popup}
        toggle={shouldShow}
        // Popup props
        // @ts-expect-error TS(2322) FIXME: Type '{ children: ReactNode; component: ForwardRef... Remove this comment to see the full error message
        arrow={<div className={stylesheet.arrow} />}
        offset={14}
        placement={direction}
        targetRef={triggerRef}
        // Underlying props
        aria-label={ariaLabel}
        role="tooltip"
        className={cx(stylesheet.tooltip, className)}
        id={id}
        {...props}
      >
        {label}
      </Animate>
    </React.Fragment>
  );
}

export default Tooltip;
