import React, { useState } from 'react';

import { EyeHideIcon, EyeShowIcon } from '@mc/wink-icons';
import Input from '../Input';
import { TranslateInput } from '../Input/TranslateInput';
import stylesheet from './InputPassword.less';

export type InputPasswordProps = {
  /** Will show in place of help text if defined also applies invalid style treatment. */
  error?: string;
  /** Text that appears below the input */
  helpText?: React.ReactNode;
  /** Visually hides the label provided by the `label` prop. */
  hideLabel?: boolean;
  /** The label of the input. */
  label?: React.ReactNode;
  /** Triggers when the input value is changed. This callback would usually handle updating the value prop. */
  onChange: (value: string) => void;
  /** The current value of the input. This component is uncontrolled so it is expected that a parent component will update this value when `onChange` is called. */
  value?: string;
};

/* A wrapper around the native browser `<input type="password" />`. Includes toggling the password asterisks. */
const InputPassword = React.forwardRef<HTMLInputElement, InputPasswordProps>(
  function InputPassword(props, forwardedRef) {
    const [isHidden, setIsHidden] = useState(true);

    // Translate default text
    const { showMsg, hideMsg } = TranslateInput();
    return (
      <Input
        {...props}
        ref={forwardedRef}
        type={isHidden ? 'password' : 'text'}
        miscText={
          <button type="button" onClick={() => setIsHidden((h) => !h)}>
            <div className={stylesheet.visibilityToggle}>
              {isHidden ? <EyeShowIcon /> : <EyeHideIcon />}
              {isHidden ? showMsg : hideMsg}
            </div>
          </button>
        }
      />
    );
  },
);

export default InputPassword;
