import React from 'react';
import cx from 'classnames';
import useId from '@mc/hooks/useId';
import ClusterLayout from '../ClusterLayout';
import Text from '../Text';
import {
  formatError,
  ERROR_MUST_PROVIDE_LABEL,
  ariaDescribedByIds,
  ariaLabelledByIds,
} from '../utils';
import stylesheet from './Meter.less';

export type MeterProps = {
  _?: $TSFixMe; // TODO: (props, propName, componentName) => { if (!props.label && !props['aria-labelledby']) { return new Error(formatError(ERROR_MUST_PROVIDE_LABEL, componentName)); } }
  action?: React.ReactNode;
  'aria-labelledby'?: string;
  className?: string;
  helpText?: React.ReactNode;
  hideLabel?: boolean;
  label?: React.ReactNode;
  max: number;
  min?: number;
  miscText?: React.ReactNode;
  size?: 'large' | 'medium' | 'small' | 'tiny';
  value: number;
  variant?:
    | ('neutral' | 'positive' | 'warning' | 'negative')
    | $TSFixMeFunction;
};

const Meter = React.forwardRef<$TSFixMe, MeterProps>(function Meter(
  {
    'aria-labelledby': ariaLabelledBy,
    className,
    action,
    hideLabel,
    helpText,
    miscText,
    label,
    size = 'medium',
    min = 0,
    max = 100,
    value,
    variant = 'neutral',
  },
  forwardedRef,
) {
  const id = useId();
  const labelId = useId();
  const helpTextId = useId();
  const miscTextId = useId();

  const fillWidth = (value / (max - min)) * 100;

  const _variant = typeof variant === 'function' ? variant(fillWidth) : variant;

  const textSize = size === 'tiny' ? 'small' : size;

  return (
    <div className={cx(stylesheet.root, className)} ref={forwardedRef}>
      <div className={stylesheet.before}>
        {label && (
          <span
            className={cx(
              'mcds-label-default',
              hideLabel && 'wink-visually-hidden',
            )}
            id={labelId}
          >
            <Text
              as="span"
              appearance={
                textSize === 'large' ? 'heading-3' : `${textSize}-bold`
              }
              className={stylesheet.label}
            >
              {label}
            </Text>
          </span>
        )}
        {miscText && (
          <Text id={miscTextId} appearance={`${textSize}-secondary`}>
            {miscText}
          </Text>
        )}
      </div>

      <div
        id={id}
        role="progressbar"
        aria-labelledby={ariaLabelledByIds(ariaLabelledBy, label && labelId)}
        aria-describedby={ariaDescribedByIds(
          helpText && helpTextId,
          miscText && miscTextId,
        )}
        aria-valuenow={value}
        aria-valuemin={min}
        aria-valuemax={max}
        className={cx(
          stylesheet.meterTrack,
          stylesheet[size],
          stylesheet[_variant],
        )}
      >
        <div
          className={stylesheet.meterFill}
          style={{
            width: fillWidth + '%',
          }}
        />
      </div>

      <ClusterLayout
        justifyContent="space-between"
        className={stylesheet.after}
      >
        {size !== 'tiny' && helpText ? (
          <Text
            as="span"
            id={helpTextId}
            appearance="small"
            className={stylesheet.helpText}
          >
            {helpText}
          </Text>
        ) : null}
        {size !== 'tiny' && action ? (
          <Text as="span" appearance="small" className={stylesheet.action}>
            {action}
          </Text>
        ) : null}
      </ClusterLayout>
    </div>
  );
});

export default Meter;
