import React, { useState } from 'react';
import cx from 'classnames';

import pluralize from '@mc/fn/pluralize';
import useId from '@mc/hooks/useId';
import {
  formatError,
  ERROR_MUST_PROVIDE_LABEL,
  ariaDescribedByIds,
  ariaLabelledByIds,
} from '../utils';

import { TranslateInput } from '../Input/TranslateInput';
import stylesheet from './Textarea.less';

export type TextareaProps = {
  _?: $TSFixMe; // TODO: (props, propName, componentName) => { if (!props.label && !props['aria-labelledby']) { return new Error(formatError(ERROR_MUST_PROVIDE_LABEL, componentName)); } }
  'aria-labelledby'?: string;
  disabled?: boolean;
  error?: string;
  helpText?: React.ReactNode;
  hideLabel?: boolean;
  label?: React.ReactNode;
  maxLength?: number;
  miscText?: React.ReactNode;
  onBlur?: $TSFixMeFunction;
  onChange: $TSFixMeFunction;
  onFocus?: $TSFixMeFunction;
  readOnly?: boolean;
  resize?: 'vertical' | 'horizontal' | 'both' | 'none';
  showCharacterCount?: boolean;
  suggestedMaxLength?: number;
  value: string;
};

const Textarea = React.forwardRef<$TSFixMe, TextareaProps>(function Textarea(
  {
    'aria-labelledby': ariaLabelledBy,
    // @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type 'Props... Remove this comment to see the full error message
    className,
    disabled = false,
    error,
    helpText,
    hideLabel = false,
    label,
    onChange,
    miscText,
    readOnly = false,
    resize = 'vertical',
    suggestedMaxLength = 0,
    onFocus = () => {},
    onBlur = () => {},
    ...props
  },
  forwardedRef,
) {
  const id = useId();
  const labelId = useId();
  const helpTextId = useId();
  const maxLengthId = useId();
  const [hasFocus, setHasFocus] = useState(false);

  const count = (props.value && props.value.length) || 0;
  const remaining = suggestedMaxLength - count;

  const { characterMsg, charactersMsg } = TranslateInput();

  return (
    <div
      className={cx(stylesheet.root, className, {
        [stylesheet.error]: !!error,
      })}
    >
      <div className={stylesheet.before}>
        {label && (
          <label
            className={cx(
              'mcds-label-default',
              hideLabel && 'wink-visually-hidden',
            )}
            id={labelId}
            htmlFor={id}
          >
            {label}
          </label>
        )}
        {suggestedMaxLength > 0 && (hasFocus || remaining < 0) ? (
          <span
            role="status"
            id={maxLengthId}
            className={cx(stylesheet.secondary, {
              [stylesheet.errorMessage]: remaining < 0,
            })}
          >
            {count}/{suggestedMaxLength}
            <span className="wink-visually-hidden">
              {' '}
              {pluralize(characterMsg, charactersMsg, count)}
            </span>
          </span>
        ) : miscText ? (
          <span className={stylesheet.secondary}>{miscText}</span>
        ) : null}
      </div>
      <textarea
        style={{ resize }}
        disabled={disabled}
        readOnly={readOnly}
        id={id}
        // We need to handle three cases:
        //
        // 1. Only pass a `label`. Since we're using a native label element,
        //    pointing `aria-labelledby` to the existing label element is
        //    unnecessary.
        // 2. Only pass an `aria-labelledby`. We don't render a label element.
        // 3. Pass both a `label` and `aria-labelledby`. We refer to both in the
        //    `aria-labelledby` attribute.
        aria-labelledby={ariaLabelledByIds(
          ariaLabelledBy,
          ariaLabelledBy && label && labelId,
        )}
        aria-describedby={ariaDescribedByIds(
          (error || helpText) && helpTextId,
          suggestedMaxLength && maxLengthId,
        )}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        onBlur={(e) => {
          setHasFocus(false);
          onBlur(e);
        }}
        onFocus={(e) => {
          setHasFocus(true);
          onFocus(e);
        }}
        ref={forwardedRef}
        {...props}
      />
      {error ? (
        <div
          id={helpTextId}
          className={cx(stylesheet.after, stylesheet.errorMessage)}
        >
          {error}
        </div>
      ) : helpText ? (
        <div
          id={helpTextId}
          className={cx(stylesheet.after, stylesheet.secondary)}
        >
          {helpText}
        </div>
      ) : null}
    </div>
  );
});

export default Textarea;
