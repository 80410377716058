import React, {
  useContext,
  useRef,
  useEffect,
  useState,
  useLayoutEffect,
} from 'react';
import cx from 'classnames';

import { MenuRightIcon, MenuLeftIcon } from '@mc/wink-icons';
import chainHandlers from '@mc/fn/chainHandlers';
import IconButton from '../IconButton';

import TabContext from './TabContext';
import getTabId from './getTabId';
import stylesheet from './TabList.less';
import { TranslateTabs } from './TranslateTabs';

export type TabListProps = {
  children?: React.ReactNode;
  onKeyDown?: $TSFixMeFunction;
};

const TabList = ({
  children,
  onKeyDown,
  // @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type 'Props... Remove this comment to see the full error message
  className,
  ...props
}: TabListProps) => {
  const {
    // @ts-expect-error TS(2339) FIXME: Property 'currentIndex' does not exist on type 'Ta... Remove this comment to see the full error message
    currentIndex,
    // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'TabContextTy... Remove this comment to see the full error message
    id,
    // @ts-expect-error TS(2339) FIXME: Property 'setCurrentIndex' does not exist on type ... Remove this comment to see the full error message
    setCurrentIndex,
    // @ts-expect-error TS(2339) FIXME: Property 'isManual' does not exist on type 'TabCon... Remove this comment to see the full error message
    isManual,
    // @ts-expect-error TS(2339) FIXME: Property 'focusIndex' does not exist on type 'TabC... Remove this comment to see the full error message
    focusIndex,
    // @ts-expect-error TS(2339) FIXME: Property 'setFocusIndex' does not exist on type 'T... Remove this comment to see the full error message
    setFocusIndex,
    // @ts-expect-error TS(2339) FIXME: Property 'setShowOutline' does not exist on type '... Remove this comment to see the full error message
    setShowOutline,
  } = useContext(TabContext);
  const tabListRef = useRef();
  const [isRightOverflow, setRightOverflow] = useState(false);
  const [isLeftOverflow, setLeftOverflow] = useState(false);
  // Translate default text
  const { scrollLeftText, scrollRightText } = TranslateTabs();

  // Handle click of overflow navigation
  const handleOverflowNavClick = (direction: $TSFixMe, multiplier = 60) => {
    const el = tabListRef.current;

    // Handle scroll on nav click
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    el.scrollLeft += direction * multiplier;
  };

  // Show/hide overflow button depending on extended direction
  const setOverflows = () => {
    const el = tabListRef.current;

    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    if (el.scrollWidth > el.clientWidth) {
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      setLeftOverflow(el.scrollLeft > 0);
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      setRightOverflow(el.scrollLeft + el.clientWidth < el.scrollWidth);
    } else {
      setLeftOverflow(false);
      setRightOverflow(false);
    }
  };

  useLayoutEffect(() => {
    // Check for overflow on window resize and scroll
    window.addEventListener('resize', setOverflows);

    // And when it loads
    setOverflows();

    // Cleanup
    return () => {
      window.removeEventListener('resize', setOverflows);
    };
  }, []);

  // Checks to see if the tabs are supposed to be controlled by manual activation.
  // If yes, when using the arrow keys to navigate between the tabs we only want to
  // draw focus on the tab current tab, and require the user to either press the Enter key
  // or click on the tab to select it
  const getTrackedIndex = () => {
    return isManual ? focusIndex : currentIndex;
  };

  const handleKeyDown = (event: $TSFixMe) => {
    const size = (children as $TSFixMe).length;
    const el = tabListRef.current;
    const tabId = getTabId(id, currentIndex);
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const tabEl = document.getElementById(tabId);

    switch (event.key) {
      case 'ArrowLeft': {
        event.preventDefault();
        const previousTabIndex = (getTrackedIndex() - 1 + size) % size;

        if (isManual) {
          setFocusIndex(previousTabIndex);
          setShowOutline(true);
        } else {
          setCurrentIndex(previousTabIndex);
        }

        // Manually set scroll into view. Sets visibilty on selected tab when navigating left
        // Opted out of using scrollIntoView library due to page jumps
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        el.scrollLeft =
          // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
          tabEl.offsetLeft - (tabEl.clientWidth + el.clientWidth) / 2;
        break;
      }
      case 'ArrowRight': {
        event.preventDefault();
        const nextTabIndex = (getTrackedIndex() + 1) % size;

        if (isManual) {
          setFocusIndex(nextTabIndex);
          setShowOutline(true);
        } else {
          setCurrentIndex(nextTabIndex);
        }

        // Manually set scroll into view. Sets visibilty on selected tab when navigating right
        // Opted out of using scrollIntoView library due to page jumps
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        el.scrollLeft = tabEl.offsetLeft + tabEl.clientWidth;
        break;
      }
      case 'Home': {
        event.preventDefault();
        setCurrentIndex(0);
        break;
      }
      case 'End': {
        event.preventDefault();
        setCurrentIndex((children as $TSFixMe).length - 1);
        break;
      }
      case ' ':
      case 'Enter': {
        if (isManual) {
          setCurrentIndex(focusIndex);
          setShowOutline(false);
        }

        const tabPanel = document.getElementById(`${tabId}:panel`);
        if (tabPanel) {
          event.preventDefault();
          tabPanel.focus();
        }
        break;
      }
      default:
        break;
    }
  };
  const cloned = React.Children.map(children, (child, index) => {
    if (child === null) {
      return null;
    }
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    return React.cloneElement(child, { index });
  });

  useEffect(() => {
    const tablist = tabListRef.current;

    // Remove .focus-within class when click is detected
    function removeFocusWithin() {
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      tablist.classList.remove(stylesheet['focus-within']);
    }

    // Add .focus-within class when keypress on TabList is detected
    function addFocusWithin() {
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      tablist.classList.add(stylesheet['focus-within']);
    }

    document.addEventListener('keydown', addFocusWithin, { capture: true });
    document.addEventListener('click', removeFocusWithin, { capture: true });

    // Cleanup
    return () => {
      document.removeEventListener('keydown', addFocusWithin, {
        capture: true,
      });
      document.removeEventListener('click', removeFocusWithin, {
        capture: true,
      });
    };
  }, []);

  // Used to position the indicator that is animated between tabs
  const [animatedIndicatorPosition, setAnimatedIndicatorPosition] = useState({
    width: 0,
    left: 0,
  });

  useEffect(() => {
    const tabId = getTabId(id, currentIndex);
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const tabEl = document.getElementById(tabId);
    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    const currentTabRectangle = tabEl.getBoundingClientRect();
    const parentRectangle = (tabListRef.current as $TSFixMe)?.getBoundingClientRect();
    setAnimatedIndicatorPosition({
      width: currentTabRectangle.width,
      left: currentTabRectangle.left - parentRectangle.left,
    });
  }, [id, currentIndex]);

  return (
    <div className={stylesheet.wrapper}>
      {isLeftOverflow && (
        <div className={cx(stylesheet.overflowNav, stylesheet.overflowLeft)}>
          <IconButton
            // Tabs are accessible on their own with keyboard navigation (arrow keys are used to navigate/scroll),
            // there is no need to access these buttons but they are visually provided to indicate
            // direction of overflow.
            aria-hidden
            // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'number'.
            tabIndex="-1"
            label={scrollLeftText}
            icon={<MenuLeftIcon />}
            onClick={() => handleOverflowNavClick(-1)}
          />
        </div>
      )}

      <div
        {...props}
        role="tablist"
        // @ts-expect-error TS(2322) FIXME: Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
        ref={tabListRef}
        className={cx(className, stylesheet.tabList)}
        // @ts-expect-error TS(2345) FIXME: Argument of type '$TSFixMeFunction | undefined' is... Remove this comment to see the full error message
        onKeyDown={chainHandlers(onKeyDown, handleKeyDown)}
        onScroll={setOverflows}
        children={cloned}
      />
      <span
        className={stylesheet.tabIndicator}
        style={{
          width: animatedIndicatorPosition.width + 'px',
          left: animatedIndicatorPosition.left + 'px',
        }}
      />
      {isRightOverflow && (
        <div className={cx(stylesheet.overflowNav, stylesheet.overflowRight)}>
          <IconButton
            aria-hidden
            // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'number'.
            tabIndex="-1"
            label={scrollRightText}
            icon={<MenuRightIcon />}
            onClick={() => handleOverflowNavClick(1)}
          />
        </div>
      )}
    </div>
  );
};

export default TabList;
