import React, { useEffect, useState, useRef } from 'react';
import cx from 'classnames';

import { InfoCircleFillIcon } from '@mc/wink-icons';
import useId from '@mc/hooks/useId';
import chainHandlers from '@mc/fn/chainHandlers';
import Animate from '../Animate';
import IconButton from '../IconButton';
import Popup from '../Popup';
import stylesheet from './Toggletip.less';

export type ToggletipProps = {
  buttonLabel?: string;
  children?: React.ReactNode;
  className?: string;
  direction?:
    | 'auto'
    | 'auto-start'
    | 'auto-end'
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'left'
    | 'left-start'
    | 'left-end';
  forceVisible?: boolean;
  label: React.ReactNode;
  unsafe_isHoverable?: boolean;
};

/**
 * A toggletip's child must support ref forwarding. Without it, the Toggletip will not know
 * how to position itself.
 */
function Toggletip({
  children,
  className,
  label,
  buttonLabel,
  direction = 'bottom',
  forceVisible = false,
  unsafe_isHoverable = false,
  ...props
}: ToggletipProps) {
  const [isHovered, setIsHovered] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [announcement, setAnnouncement] = useState();
  const triggerRef = useRef();
  const id = useId();

  if (!children) {
    if (__DEV__ || __TEST__) {
      if (!buttonLabel) {
        console.error(
          'Toggletips must have a buttonLabel prop or a children prop.',
        );
      }
    }
    children = <IconButton label={buttonLabel} icon={<InfoCircleFillIcon />} />;
  }

  React.Children.only(children);

  const toggle = () => setIsVisible(!isVisible);
  const hide = () => setIsVisible(false);
  const shouldShow = forceVisible || isVisible || isHovered;

  useEffect(() => {
    let timeout: $TSFixMe;
    if (isVisible && !announcement) {
      timeout = setTimeout(() => {
        // @ts-expect-error TS(2345) FIXME: Argument of type 'ReactNode' is not assignable to ... Remove this comment to see the full error message
        setAnnouncement(label);
      }, 100);
    }

    return () => clearTimeout(timeout);
  });

  return (
    <React.Fragment>
      {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
      {React.cloneElement(children, {
        onClick: chainHandlers((children as $TSFixMe).props.onClick, () => {
          toggle();
          // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
          setAnnouncement();
          // Safari/Firefox don't focus buttons on click
          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          triggerRef.current.focus();
        }),
        onMouseEnter: chainHandlers(
          (children as $TSFixMe).props.onMouseEnter,
          () => {
            if (unsafe_isHoverable) {
              setIsHovered(true);
            }
          },
        ),
        onMouseLeave: chainHandlers(
          (children as $TSFixMe).props.onMouseLeave,
          () => {
            if (unsafe_isHoverable) {
              setIsHovered(false);
            }
          },
        ),
        onBlur: chainHandlers((children as $TSFixMe).props.onBlur, hide),
        ref: triggerRef,
      })}
      <Animate
        component={Popup}
        toggle={shouldShow}
        // Popup props
        // @ts-expect-error TS(2322) FIXME: Type '{ children: ReactNode; component: ForwardRef... Remove this comment to see the full error message
        arrow={<div className={stylesheet.arrow} />}
        offset={12}
        placement={direction}
        targetRef={triggerRef}
        // Underlying props
        className={cx(stylesheet.toggletip, className)}
        id={id}
        {...props}
      >
        {label}
      </Animate>
      <span role="status" aria-atomic="true">
        <span className="wink-visually-hidden">
          {isVisible ? announcement : ''}
        </span>
      </span>
    </React.Fragment>
  );
}

export default Toggletip;
