import React, { useRef, useEffect, useState } from 'react';

import { MenuDownIcon } from '@mc/wink-icons';
import useId from '@mc/hooks/useId';
import usePrevious from '@mc/hooks/usePrevious';
import useJoinedRef from '@mc/hooks/useJoinedRef';
import useUpdateEffect from '@mc/hooks/useUpdateEffect';
import chainHandlers from '@mc/fn/chainHandlers';
import Popup from '../Popup';
import Input from '../Input';
import stylesheet from './Combobox.less';

const closestMatchIndex = (options: $TSFixMe, value: $TSFixMe) => {
  const lowercaseValue = value.toLowerCase();
  return options
    .map((option: $TSFixMe) => option.toLowerCase())
    .findIndex((option: $TSFixMe) => option.startsWith(lowercaseValue));
};

export type ComboboxProps = {
  'aria-autocomplete': 'none' | 'list';
  'aria-labelledby'?: string;
  'aria-multiselectable'?: $TSFixMe; // TODO: PropTypes.oneOf([true])
  autoFocus?: boolean;
  autohighlight?: boolean;
  children?: React.ReactNode;
  className?: string;
  closeOnSelect?: boolean;
  disabled?: boolean;
  error?: string;
  helpText?: React.ReactNode;
  hideLabel?: boolean;
  label?: React.ReactNode;
  miscText?: React.ReactNode;
  onBlur?: $TSFixMeFunction;
  onChange: $TSFixMeFunction;
  onClick?: $TSFixMeFunction;
  onKeyDown?: $TSFixMeFunction;
  onSelect?: $TSFixMeFunction;
  readOnly?: boolean;
  unsafe_key?: $TSFixMe;
  value?: string;
};

const Combobox = React.forwardRef<$TSFixMe, ComboboxProps>(function Combobox(
  {
    'aria-multiselectable': ariaMultiselectable,
    children,
    label,
    onChange,
    value = '',
    onSelect = () => {},
    closeOnSelect = true,
    autohighlight = false,
    unsafe_key,
    autoFocus = false,
    ...props
  },
  forwardedRef,
) {
  const firstActiveDescendant = autohighlight ? 0 : -1;
  const [isExpanded, setIsExpanded] = useState(autoFocus);
  const [activeDescendant, setActiveDescendant] = useState(
    firstActiveDescendant,
  );
  const ref = useRef();
  const inputRef = useJoinedRef(forwardedRef, ref);
  const optionsRef = useRef([]);
  const id = useId();
  const labelId = `${id}-label`;
  const listboxId = `${id}-listbox`;
  const activeDescendantId = `${id}-active`;

  const handleSelect = (selected: $TSFixMe) => {
    onChange(selected);
    onSelect(selected);
    if (closeOnSelect) {
      setIsExpanded(false);
    }
  };

  const options = React.Children.toArray(children)
    .filter((option) => option)
    .map((option, index) => {
      const isHighlighted = index === activeDescendant;
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      return React.cloneElement(option, {
        onSelect: handleSelect,
        setActive: () => setActiveDescendant(index),
        id: isHighlighted ? activeDescendantId : undefined,
        isHighlighted,
      });
    });

  const previousOptions = usePrevious(options);
  // Updates to previousHighlight shouldn't trigger effects, so we use a ref.
  const previousHighlight = useRef();
  previousHighlight.current = (previousOptions?.[
    activeDescendant
  ] as $TSFixMe)?.props.value;

  // Reset highlighted option when listbox closes
  useUpdateEffect(() => {
    if (!isExpanded) {
      setActiveDescendant(firstActiveDescendant);
    }
  }, [firstActiveDescendant, isExpanded]);

  // Highlight options while user types.
  useUpdateEffect(() => {
    if (!autohighlight) {
      // If we're filtering options, the activedescendant will be out of date,
      // so reset the activedescendant when not auto-highlighting.
      setActiveDescendant(firstActiveDescendant);
    } else {
      // Attempt to maintain the old highlighted value.
      const previousHighlightIndex = optionsRef.current
        .map((option) => (option as $TSFixMe).props.value)
        .indexOf(previousHighlight.current);
      if (previousHighlightIndex >= 0) {
        // Maintain the old highlighted value
        setActiveDescendant(previousHighlightIndex);
      } else {
        // Autohighlight the closest matching option while the user types.
        const values = optionsRef.current.map(
          (option) => (option as $TSFixMe).props.value,
        );
        const optionIndex = closestMatchIndex(values, value);
        setActiveDescendant(
          optionIndex >= 0 ? optionIndex : firstActiveDescendant,
        );
      }
    }
  }, [value, autohighlight, firstActiveDescendant]);

  // @ts-expect-error TS(2322) FIXME: Type 'DetailedReactHTMLElement<any, HTMLElement>[]... Remove this comment to see the full error message
  optionsRef.current = options;

  const handleKeyDown = (event: $TSFixMe) => {
    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault();
        if (!isExpanded) {
          const lastActiveDescendant = autohighlight ? options.length - 1 : -1;
          setActiveDescendant(lastActiveDescendant);
        } else {
          setActiveDescendant((prev) => {
            return prev === firstActiveDescendant
              ? options.length - 1
              : prev - 1;
          });
        }
        setIsExpanded(true);
        break;

      case 'ArrowDown':
        event.preventDefault();
        if (!isExpanded) {
          setActiveDescendant(firstActiveDescendant);
        } else {
          setActiveDescendant((prev) => {
            return prev === options.length - 1
              ? firstActiveDescendant
              : prev + 1;
          });
        }
        setIsExpanded(true);
        break;

      case 'Enter':
        if (isExpanded) {
          // Prevent form submissions while the dropdown is open.
          event.preventDefault();
        }

        if (isExpanded && options[activeDescendant]) {
          handleSelect(options[activeDescendant].props.value);
        } else {
          setIsExpanded((prev) => !prev);
        }
        break;

      case 'Escape':
        event.preventDefault();
        setIsExpanded(false);
        break;

      default:
        break;
    }
  };

  const shouldShowListbox = isExpanded && options.length > 0;
  return (
    <React.Fragment>
      <Input
        {...props}
        // @ts-expect-error TS(2322) FIXME: Type '{ role: string; className: any; autoComplete... Remove this comment to see the full error message
        role="combobox"
        className={stylesheet.root}
        autoComplete="off"
        id={id}
        label={label}
        ref={inputRef}
        aria-haspopup="listbox"
        suffixText={<MenuDownIcon className={stylesheet.chevron} />}
        aria-expanded={shouldShowListbox}
        aria-activedescendant={
          activeDescendant !== -1 && shouldShowListbox
            ? activeDescendantId
            : undefined
        }
        aria-controls={shouldShowListbox ? listboxId : undefined}
        autoFocus={autoFocus}
        value={value}
        // @ts-expect-error TS(2345) FIXME: Argument of type '$TSFixMeFunction | undefined' is... Remove this comment to see the full error message
        onBlur={chainHandlers(props.onBlur, () => {
          setIsExpanded(false);
        })}
        onChange={(changed) => {
          if (!isExpanded) {
            setIsExpanded(true);
          }
          onChange(changed);
        }}
        // @ts-expect-error TS(2345) FIXME: Argument of type '$TSFixMeFunction | undefined' is... Remove this comment to see the full error message
        onClick={chainHandlers(props.onClick, () =>
          setIsExpanded((prev) => !prev),
        )}
        // @ts-expect-error TS(2345) FIXME: Argument of type '$TSFixMeFunction | undefined' is... Remove this comment to see the full error message
        onKeyDown={chainHandlers(props.onKeyDown, handleKeyDown)}
      />
      {isExpanded && (
        <Popup
          matchTargetWidth
          // Set an offset of 3px so listbox shows below input borders
          offset={3}
          placement="bottom-start"
          targetRef={ref}
          // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; matchTargetWidth: true;... Remove this comment to see the full error message
          className={stylesheet.popup}
          unsafe_key={unsafe_key}
        >
          <ul
            id={listboxId}
            aria-labelledby={labelId}
            role="listbox"
            aria-multiselectable={ariaMultiselectable}
            // preventDefault stops the main input from losing focus.
            onMouseDown={(event) => {
              event.preventDefault();
            }}
          >
            {options}
          </ul>
        </Popup>
      )}
    </React.Fragment>
  );
});

export default Combobox;
