import React from 'react';
import { getNestedValue, setNestedValue } from '@mc/fn/nestedValue';
import Form from '../Form';
import { useWizardActions, useWizardState } from './Wizard';

const defaultNextStep = ({ currentStep, stepNames }: $TSFixMe) => {
  const nextStep = stepNames[stepNames.indexOf(currentStep) + 1];
  return nextStep;
};

const noop = () => {};

export type WizardFormProps = {
  nextStep?: string | $TSFixMeFunction;
  onSubmit?: $TSFixMeFunction;
};

function WizardForm({
  onSubmit = noop,
  nextStep = defaultNextStep,
  ...props
}: WizardFormProps) {
  const wizardActions = useWizardActions();
  const wizardState = useWizardState();

  let _nextStep = nextStep;
  if (typeof nextStep === 'function') {
    _nextStep = nextStep(wizardState);
  }

  const finishWizard = () => {
    // If there are no more next steps, we can mark the Wizard as finished.
    if (!_nextStep) {
      wizardActions.finish();
    }
  };

  const handleSubmit = async (data: $TSFixMe) => {
    const result = await onSubmit(data);
    // Sync data to the wizard inventory if its valid form data (no errors
    // returned) and additional values are supplied. Once data is synced,
    // proceed to the next step, or if no more next steps exist, finish up.
    const hasErrors = result && result.errors;
    if (hasErrors) {
      return result;
    }

    if (result && result.values) {
      wizardActions.setInventory((inventory: $TSFixMe) => {
        return {
          ...inventory,
          ...result.values,
        };
      }, finishWizard); // After inventory is saved, attempt to finish.
    } else {
      finishWizard();
    }

    if (_nextStep && result !== false) {
      wizardActions.navigate(_nextStep);
    }
  };

  // Sync all form data changes with the wizard inventory
  const handleChange = (
    changed: $TSFixMe,
    values: $TSFixMe,
    name: $TSFixMe,
  ) => {
    // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
    wizardActions.setInventory((inventory: $TSFixMe) => {
      const value = getNestedValue(changed, name);
      const nextInventory = setNestedValue(inventory, name, value);
      return nextInventory;
    });
  };

  return (
    <Form
      {...props}
      initialValues={(wizardState as $TSFixMe).inventory}
      // @ts-expect-error TS(2322) FIXME: Type '(data: $TSFixMe) => Promise<any>' is not ass... Remove this comment to see the full error message
      onSubmit={handleSubmit}
      // @ts-expect-error TS(2322) FIXME: Type '(changed: $TSFixMe, values: $TSFixMe, name: ... Remove this comment to see the full error message
      onChange={handleChange}
    />
  );
}

export default WizardForm;
