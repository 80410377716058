import React from 'react';
import classnames from 'classnames';

import { DeleteIcon, FileIcon } from '@mc/wink-icons';
import humanizeBytes from '@mc/fn/humanizeBytes';
import Loading from '../Loading';
import ClusterLayout from '../ClusterLayout';
import IconButton from '../IconButton';
import Input from '../Input';
import StackLayout from '../StackLayout';
import Text from '../Text';

import { TranslateFileInput } from './TranslateFileInput';
import stylesheet from './File.less';

const IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/gif'];

export type FileProps = {
  file: {
    name?: string;
    size?: number;
    thumbnail?: string;
    type?: string;
  };
  isLoading?: boolean;
  onRemove?: $TSFixMeFunction;
  showImagePreviews?: boolean;
};

/**
 * A File represents a single file uploaded through the FileInput input
 */
export const File = React.forwardRef<$TSFixMe, FileProps>(function File(
  { file, onRemove, isLoading = false, showImagePreviews = false },
  forwardedRef,
) {
  // Translate default text
  // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
  const { removeFileMsg } = TranslateFileInput();

  //If the file is an image this provides a nifty little preview
  let thumbnailUrl;
  if (showImagePreviews) {
    thumbnailUrl = file.thumbnail;
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    if (!thumbnailUrl && IMAGE_TYPES.includes(file.type)) {
      const urlCreator = window?.URL || window?.webkitURL;
      // @ts-expect-error TS(2345) FIXME: Argument of type '{ name?: string | undefined; siz... Remove this comment to see the full error message
      thumbnailUrl = urlCreator?.createObjectURL(file);
    }
  }

  return (
    <ClusterLayout
      gap={4}
      nowrap={true}
      ref={forwardedRef}
      className={stylesheet.file}
      justifyContent="space-between"
    >
      <div role="status" aria-live="polite" aria-atomic="true">
        {isLoading ? (
          <Loading />
        ) : thumbnailUrl ? (
          <img className={stylesheet.thumbnail} src={thumbnailUrl} alt="" />
        ) : (
          <FileIcon />
        )}
      </div>
      <StackLayout gap={0} style={{ flexGrow: 1 }}>
        <Text className={stylesheet.nameText} appearance="small-bold">
          {file.name}
        </Text>
        {file.size !== undefined && !isLoading ? (
          <Text appearance="small-secondary">{humanizeBytes(file.size)}</Text>
        ) : null}
      </StackLayout>
      {!isLoading ? (
        <IconButton
          className={stylesheet.removeButton}
          label={removeFileMsg}
          onClick={onRemove}
          icon={<DeleteIcon />}
        />
      ) : null}
    </ClusterLayout>
  );
});

export type AltTextFileProps = {
  altErrorText?: string;
  altHelpText?: string;
  file: {
    name?: string;
    size?: number;
  };
  index: number;
  isLoading?: boolean;
  label?: string;
  maxAltTextLength?: number;
  onChange?: $TSFixMeFunction;
  onRemove: $TSFixMeFunction;
  showImagePreviews?: boolean;
  value?: string[];
};

export const AltTextFile = React.forwardRef<$TSFixMe, AltTextFileProps>(
  function AltTextFile(
    {
      value,
      onChange,
      label = 'Alt Text',
      file,
      onRemove,
      isLoading,
      maxAltTextLength,
      altErrorText,
      altHelpText,
      index,
      showImagePreviews = false,
    },
    forwardedRef,
  ) {
    /**
     * Update the Alttext for the file at the current index in the array
     * @param {string} text
     */
    const updateAltText = (text: $TSFixMe) => {
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      const updatedValueArr = value.slice();
      updatedValueArr[index] = text;
      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      onChange(updatedValueArr);
    };

    const cleanUpAltTextAndFile = () => {
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      const altTextArray = value.slice();
      altTextArray.splice(index, 1);
      // Cleans up alt text
      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      onChange(altTextArray);
      // Removes the file
      onRemove();
    };

    return (
      <StackLayout
        className={classnames(
          stylesheet.container,
          stylesheet.altStyleContainer,
        )}
      >
        <File
          ref={forwardedRef}
          showImagePreviews={showImagePreviews}
          {...{ file, onRemove: cleanUpAltTextAndFile, isLoading }}
        />

        <Input
          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          value={value[index]}
          onChange={updateAltText}
          className={stylesheet.altText}
          label={label}
          placeholder="Enter some alt text describing your image"
          suggestedMaxLength={maxAltTextLength}
          helpText={altHelpText}
          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          error={value[index]?.length > maxAltTextLength ? altErrorText : ''}
        />
      </StackLayout>
    );
  },
);
