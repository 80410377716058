import React, { useContext } from 'react';
import ListboxOption from '../Listbox/Option';
import { ListboxModeContext } from '../Listbox/Listbox';

export type OptionProps = {
  children: React.ReactNode;
  label?: string;
};

const Option = ({ label, ...props }: OptionProps) => {
  const isListbox = useContext(ListboxModeContext);
  if (__DEV__) {
    if (isListbox && !label) {
      console.error(
        `when Select is given a prop of mode="listbox" (or the prop multiple), Options must have a label.`,
      );
    }
  }
  // Label is not passed as a prop for Option but used by Listbox. Specifically for
  // screen reader users or filtering/search. See Listbox/Listbox.js for context on how label is used.
  return isListbox ? <ListboxOption {...props} /> : <option {...props} />;
};

export default Option;
