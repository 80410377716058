import React from 'react';
import cx from 'classnames';

import { MenuDownIcon } from '@mc/wink-icons';
import chainHandlers from '@mc/fn/chainHandlers';
import useId from '@mc/hooks/useId';
import Listbox from '../Listbox';
import {
  formatError,
  ERROR_MUST_PROVIDE_LABEL,
  ariaLabelledByIds,
} from '../utils';
import emulateSelectKeyboardSearch from './emulateSelectKeyboardSearch';
import stylesheet from './SelectInline.less';
import { TranslateSelect } from './TranslateSelect';

const defaultRenderSelectedValue = (selected: $TSFixMe, label: $TSFixMe) => {
  const isMultiple = Array.isArray(selected);
  const isSelected = isMultiple ? selected.length > 0 : !!selected;

  if (!isSelected) {
    return label;
  }

  if (isMultiple) {
    const { multipleSelected } = TranslateSelect(selected.length);
    return selected.length > 1
      ? multipleSelected
      : selected.length === 1
      ? selected.map((v) => v.children || v.value)
      : label;
  }

  return selected.children;
};

type SelectInlineListboxTriggerProps = {
  filter?: string;
  isExpanded: boolean;
  onBlur: $TSFixMeFunction;
  onFilterChange?: $TSFixMeFunction;
  onHighlight: $TSFixMeFunction;
  onKeyDown: $TSFixMeFunction;
  onSelect: $TSFixMeFunction;
  onToggle: $TSFixMeFunction;
  options: $TSFixMe[];
  placeholder?: React.ReactNode;
  renderSelectedValue?: $TSFixMeFunction;
  selected?: $TSFixMe[];
};

const SelectInlineListboxTrigger = React.forwardRef<
  $TSFixMe,
  SelectInlineListboxTriggerProps
>(
  (
    {
      selected = [],
      placeholder,
      renderSelectedValue,
      isExpanded,
      filter,
      onFilterChange,
      options,
      onBlur,
      onHighlight,
      onSelect,
      onKeyDown,
      onToggle,
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <React.Fragment>
        <span
          tabIndex={0}
          className={stylesheet.selectedValue}
          {...props}
          ref={forwardedRef}
          onKeyDown={chainHandlers(onKeyDown, (event: $TSFixMe) => {
            emulateSelectKeyboardSearch(event, {
              options,
              isExpanded,
              onHighlight,
              onSelect,
              onToggle,
            });
          })}
          onClick={onToggle}
          onBlur={onBlur}
        >
          {/* @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message */}
          {renderSelectedValue(selected, placeholder)}
          <span className={stylesheet.indicator}>
            <MenuDownIcon />
          </span>
        </span>
      </React.Fragment>
    );
  },
);

export type SelectInlineProps = {
  _?: $TSFixMe; // TODO: (props, propName, componentName) => { if (!props.label && !props['aria-labelledby']) { return new Error(formatError(ERROR_MUST_PROVIDE_LABEL, componentName)); } }
  'aria-labelledby'?: string;
  children: React.ReactNode;
  disabled?: boolean;
  hideLabel?: boolean;
  label?: React.ReactNode;
  miscText?: $TSFixMe;
  mode?: 'native' | 'listbox';
  multiple?: boolean;
  onChange: $TSFixMeFunction;
  placeholder?: string;
  readOnly?: boolean;
  renderSelectedValue?: $TSFixMeFunction;
  value?: $TSFixMe;
};

const SelectInline = React.forwardRef<$TSFixMe, SelectInlineProps>(
  function SelectInline(
    {
      'aria-labelledby': ariaLabelledBy,
      // @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type 'Selec... Remove this comment to see the full error message
      className,
      children,
      // miscText isn't being used in this component yet, but in the meantime it needs
      // to be filtered out from the rest props passed to the DOM node.
      miscText,
      mode = 'native',
      multiple = false,
      disabled = false,
      readOnly = false,
      placeholder,
      hideLabel,
      label,
      onChange,
      value,
      renderSelectedValue = defaultRenderSelectedValue,
      ...props
    },
    forwardedRef,
  ) {
    const id = useId();
    const labelId = useId();

    let selected;
    React.Children.forEach(children, (child) => {
      if ((child as $TSFixMe)?.props.value === value) {
        selected = (child as $TSFixMe).props;
      }
    });

    const isListbox = mode === 'listbox' || multiple;

    // We need to handle three cases:
    //
    // 1. Only pass a `label`. Native selects use a native label element, but
    //    Listbox isn't a native select so it must manually use `aria-labelledby`.
    // 2. Only pass an `aria-labelledby`. We don't render a label element.
    // 3. Pass both a `label` and `aria-labelledby`. We refer to both in the
    //    `aria-labelledby` attribute.
    const labelledBy = ariaLabelledByIds(
      ariaLabelledBy,
      (ariaLabelledBy || isListbox) && label && labelId,
    );

    return (
      <div
        className={cx(stylesheet.root, className, {
          [stylesheet.disabled]: disabled,
        })}
      >
        {label && (
          <label
            id={labelId}
            htmlFor={id}
            className={cx(hideLabel && 'wink-visually-hidden')}
          >
            {label}
          </label>
        )}
        {isListbox ? (
          <Listbox
            className={stylesheet.listbox}
            renderSelectedValue={renderSelectedValue}
            value={value}
            multiple={multiple}
            // @ts-expect-error TS(2739) FIXME: Type 'ForwardRefExoticComponent<SelectInlineListbo... Remove this comment to see the full error message
            trigger={SelectInlineListboxTrigger}
            disabled={disabled}
            readOnly={readOnly}
            placeholder={placeholder}
            id={id}
            aria-labelledby={labelledBy}
            onChange={onChange}
            {...props}
          >
            {children}
          </Listbox>
        ) : (
          <React.Fragment>
            <select
              value={value}
              disabled={disabled}
              // @ts-expect-error TS(2322) FIXME: Type '{ children: (ReactNode | Element)[]; _?: any... Remove this comment to see the full error message
              readOnly={readOnly}
              id={id}
              aria-labelledby={labelledBy}
              onChange={(event) => onChange(event.target.value)}
              ref={forwardedRef}
              {...props}
            >
              {placeholder && (
                <option value="" disabled>
                  {placeholder}
                </option>
              )}
              {children}
            </select>
            <span
              data-testid="selectedValue"
              className={stylesheet.selectedValue}
              aria-hidden="true"
            >
              {renderSelectedValue(selected, placeholder)}
              <span className={stylesheet.indicator}>
                <MenuDownIcon />
              </span>
            </span>
          </React.Fragment>
        )}
      </div>
    );
  },
);

export default SelectInline;
