import React, { useRef, useState, useEffect } from 'react';
import cx from 'classnames';
// eslint-disable-next-line no-restricted-imports
import { useTransition, animated, Globals } from 'react-spring';
import useId from '@mc/hooks/useId';
import useBodyClass from '@mc/hooks/useBodyClass';
import usePrefersReducedMotion from '@mc/hooks/usePrefersReducedMotion';
import { useDsTranslateMessage } from '@mc/wink/internationalization/useDsTranslateMessage';
import { mcdsFlagCheck } from '@mc/wink/helpers/utils-ts';

import Dialog from '../Dialog';
import ClusterLayout from '../ClusterLayout';
import Heading from '../Heading';
import Portal from '../Portal';
import TextButton from '../TextButton';

import stylesheet from './BottomSheet.less';

const SNAP_THRESHOLD = 0.4;

const AnimatedDialog = animated(Dialog);

type BottomSheetOverlayProps = {
  isCloseable?: boolean;
  isOpen: boolean;
  onRequestClose: $TSFixMeFunction;
};

const BottomSheetOverlay = React.forwardRef<$TSFixMe, BottomSheetOverlayProps>(
  function BottomSheetOverlay({ isOpen, isCloseable, onRequestClose }, ref) {
    const transitions = useTransition(isOpen, {
      from: { opacity: 0, scale: 1 },
      enter: { opacity: 1, scale: 1 },
      leave: { opacity: 0, scale: 1 },
    });

    return transitions((style, item) => {
      return (
        item && (
          <animated.div
            ref={ref}
            className={stylesheet.overlay}
            onClick={isCloseable ? onRequestClose : undefined}
            style={style}
          />
        )
      );
    });
  },
);

export type BottomSheetProps = {
  breadcrumbs?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  closeButtonText?: string;
  isCloseable?: boolean;
  isExpandable?: boolean;
  isExpandedOnOpen?: boolean;
  isOpen: boolean;
  onRequestClose: $TSFixMeFunction;
  title: React.ReactNode;
};

/**
 * Modals act as a focused dialog with our users.
 * Typically, they should only be used to present inconsequential information,
 * act as a confirmation, or perform a singular task or action (such as fill out a form).
 */

const BottomSheet = React.forwardRef<$TSFixMe, BottomSheetProps>(function Modal(
  {
    children,
    className,
    closeButtonText,
    isCloseable = true,
    isOpen,
    title,
    breadcrumbs,
    onRequestClose,
    isExpandable,
    isExpandedOnOpen = false,
    ...rest
  },
  forwardedRef,
) {
  const id = useId();
  useBodyClass(isOpen, stylesheet.lockScroll);

  const sheetRef = useRef();
  const contentRef = useRef();
  const tapOffset = useRef(0);
  const tapPosition = useRef(0);
  const isDragging = useRef(false);
  const [presentation, setPresentation] = useState(
    isExpandedOnOpen ? 'expanded' : 'open',
  );
  const prefersReducedMotion = usePrefersReducedMotion();

  const transitions = useTransition(isOpen, {
    from: { transform: 'translateY(100%)', opacity: 0.75 },
    enter: { transform: 'translateY(0%)', opacity: 1 },
    leave: { transform: 'translateY(100%)', opacity: 0.75 },
    config: { mass: 1, tension: 248, friction: 26 },
  });

  // Translation for default text
  const defaultCloseButtonText = useRef(
    useDsTranslateMessage({
      id: 'mcds.bottom_sheet.close_button_text',
      defaultMessage: 'Close',
    }),
  );

  closeButtonText = closeButtonText || defaultCloseButtonText.current;

  // Remove animation when preferesReducedMotion is enabled
  useEffect(() => {
    Globals.assign({
      skipAnimation: prefersReducedMotion,
    });
  }, [prefersReducedMotion]);

  useEffect(() => {
    const contentElement = document.querySelector(
      '.wink-bottom-sheet-receding-content',
    );

    if (contentElement) {
      contentElement.classList.toggle(
        'wink-bottom-sheet-receding-content-active',
        isOpen,
      );
    }
  }, [isOpen]);

  // update presentation on open/close
  useEffect(() => {
    if (isOpen) {
      // ensure presentation matches isExpandedOnOpen preference
      setPresentation(isExpandedOnOpen ? 'expanded' : 'open');
    } else {
      setPresentation('closed');
    }
  }, [isExpandedOnOpen, isOpen]);

  function startDrag(e: $TSFixMe) {
    tapPosition.current = e.targetTouches[0].clientY;
  }

  function onDrag(e: $TSFixMe) {
    tapOffset.current = tapPosition.current - e.targetTouches[0].clientY;
    tapPosition.current = e.targetTouches[0].clientY;

    // allow user to scroll inner content without dragging
    if (
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      (tapOffset.current > 0 || contentRef.current.scrollTop > 0) &&
      presentation === 'expanded'
    ) {
      return;
    }

    if (!isDragging.current) {
      setPresentation('dragging');
    }
    isDragging.current = Math.abs(tapOffset.current) > 0;

    // stop user from being able to drag up if at min margin
    if (
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      sheetRef.current.offsetTop - tapOffset.current <
      window.innerHeight * 0.05
    ) {
      return;
    }

    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    sheetRef.current.style.top =
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      sheetRef.current.offsetTop - tapOffset.current + 'px';
  }

  function stopDragging() {
    // make sure we don't mess up small swipes with an actual drag
    if (!isDragging.current) {
      return;
    }

    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    const top = parseInt(sheetRef.current.style.top, 10);

    if (top > window.innerHeight * 0.9) {
      // swipe down to close
      onRequestClose();
    } else if (top < window.innerHeight * SNAP_THRESHOLD) {
      setPresentation('expanded');
    } else if (top >= window.innerHeight * SNAP_THRESHOLD) {
      setPresentation('open');
    }

    isDragging.current = false;
  }

  return transitions((style, item) => {
    return (
      item && (
        <React.Fragment>
          {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; className: string; }' i... Remove this comment to see the full error message */}
          <Portal className="wink-bottom-sheet-overlay">
            <BottomSheetOverlay
              onRequestClose={onRequestClose}
              isCloseable={isCloseable}
              isOpen={isOpen}
            />
          </Portal>

          <AnimatedDialog
            className={cx(stylesheet.container, stylesheet[presentation], {
              [stylesheet.expandable]: isExpandable,
            })}
            onRequestClose={onRequestClose}
            aria-labelledby={id}
            // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; className: string; onRe... Remove this comment to see the full error message
            onTouchStart={isExpandable ? startDrag : null}
            onTouchMove={isExpandable ? onDrag : null}
            onTouchEnd={isExpandable ? stopDragging : null}
            ref={sheetRef}
            style={style}
          >
            <div
              className={cx(stylesheet.root, className)}
              ref={forwardedRef}
              {...rest}
            >
              {isExpandable && <div className={stylesheet.dragIndicator} />}
              <ClusterLayout
                as="header"
                gap={0}
                justifyContent="space-between"
                className={stylesheet.header}
              >
                <div>
                  <Heading
                    // @ts-expect-error TS(2322) FIXME: Type '{ children: ReactNode; id: string; level: 1;... Remove this comment to see the full error message
                    id={id}
                    level={1}
                    appearance={
                      breadcrumbs
                        ? mcdsFlagCheck('xp_project_runway_design_foundation')
                          ? 'heading-6'
                          : 'heading-4'
                        : mcdsFlagCheck('xp_project_runway_design_foundation')
                        ? 'heading-5'
                        : 'heading-3'
                    }
                  >
                    {title}
                  </Heading>
                  {breadcrumbs}
                </div>
                <TextButton onClick={onRequestClose}>
                  {closeButtonText}
                </TextButton>
              </ClusterLayout>

              {/* @ts-expect-error TS(2322) FIXME: Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message */}
              <main className={stylesheet.body} ref={contentRef}>
                {children}
              </main>
            </div>
          </AnimatedDialog>
        </React.Fragment>
      )
    );
  });
});

export default BottomSheet;
