import React from 'react';
import cx from 'classnames';

import stylesheet from './Modal.less';

export type ModalSideViewProps = {
  /** The side view content inside the modal.  */
  children?: React.ReactNode;
  /** Additional class names to apply to the side view. */
  className?: string;
};

const ModalSideView = ({
  children,
  className,
  ...rest
}: ModalSideViewProps) => {
  return (
    <div className={cx(stylesheet.sideView, className)} {...rest}>
      {children}
    </div>
  );
};

export default ModalSideView;
