import React, { forwardRef } from 'react';
import cx from 'classnames';
import Select from '../Select/Select';
import stylesheet from './PageHeaderSelect.css';

const renderSelectedValue = (
  selected: { value: string }[],
  placeholder: string,
) => {
  return selected.length > 1
    ? `${selected.length} selected`
    : selected.length === 1
    ? selected.map((selection) => selection.value)
    : placeholder;
};

export type PageHeaderSelectProps = {
  /**
   * Optional badge component to display next to the select
   */
  badge?: React.ReactNode;
  /**
   *  Link for the call to action
   *  This prop is only relevant in listbox mode
   */
  callToActionHref?: string;
  /**
   *  Label for the call to action at the bottom of the open menu
   *  This prop is only relevant in listbox mode
   */
  callToActionLabel?: string;
  /**
   *  Click handler for the call to action at the bottom of the open menu
   *  This prop is only relevant in listbox mode
   */
  callToActionOnClick?: () => void;
  children?: React.ReactNode;
  className?: string;
  hideLabel?: boolean;
  label: string;
  /** Triggers when the input value is changed. This callback would usually handle updating the value prop. */
  onChange: (value: string) => void;
  /**
   * Option to control the width of the select menu. Defaults to `regular`.
   */
  size?: 'small' | 'regular';
  /** The current value of the input. This component is uncontrolled so it is expected that a parent component will update this value when `onChange` is called. */
  value: string;
};

const PageHeaderSelect = forwardRef<HTMLElement, PageHeaderSelectProps>(
  function(
    {
      children,
      className,
      callToActionLabel,
      callToActionOnClick,
      callToActionHref,
      badge,
      size = 'regular',
      ...props
    },
    forwardedRef,
  ) {
    const classes = cx(stylesheet.root, className, {
      [stylesheet.smallMenu]: size === 'small',
    });

    return (
      <div className={stylesheet.menuBadgeContainer}>
        <Select
          callToActionOnClick={callToActionOnClick}
          callToActionHref={callToActionHref}
          callToActionLabel={callToActionLabel}
          mode="listbox"
          matchTargetWidth={false}
          // @ts-expect-error TS(2322) FIXME: Type '{ children: ReactNode; hideLabel?: boolean |... Remove this comment to see the full error message
          className={classes}
          renderSelectedValue={renderSelectedValue}
          ref={forwardedRef}
          {...props}
        >
          {children}
        </Select>
        <div className={stylesheet.badge}>{badge}</div>
      </div>
    );
  },
);

export default PageHeaderSelect;
