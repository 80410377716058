import React, { useEffect, useRef, useState } from 'react';
import { format, parse } from 'date-fns';

import { CalendarIcon } from '@mc/wink-icons';
import useId from '@mc/hooks/useId';
import { SUPPORTED_LOCALES_DATE } from '@mc/internationalization/constants';
import Calendar from '../Calendar';
import IconButton from '../IconButton';
import Input from '../Input';
import Popup from '../Popup';
// TODO:
// Make this into a wink-specific hook?
// Inline it into Popup and flag it behind a prop?
import hideAppLevelElements from '../Dialog/ariaHider';
import { TranslateInput } from '../Input/TranslateInput';
import { TranslatedDays } from '../Calendar/TranslateCalendar';
import stylesheet from './InputDate.less';

const NOW = new Date();

let unhideAppLevelElements = () => {};

export type CalendarPopupProps = {
  dateFilter?: $TSFixMeFunction;
  max?: $TSFixMe; // TODO: PropTypes.instanceOf(Date)
  min?: $TSFixMe; // TODO: PropTypes.instanceOf(Date)
  onChange: $TSFixMeFunction;
  onRequestClose: $TSFixMeFunction;
  startDayOfWeek?: string;
  targetRef: $TSFixMe;
  value: $TSFixMe; // TODO: PropTypes.instanceOf(Date)
};

export function CalendarPopup({
  targetRef,
  onRequestClose,
  ...props
}: CalendarPopupProps) {
  const ref = useRef(null);
  const describedbyId = useId();
  const { inputDateMsg } = TranslateInput();

  // Handle focus management on open/close
  // This was lifted from @mc/wink/components/Dialog
  useEffect(() => {
    const previousFocus = document.activeElement;
    const root = ref.current;
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    root.focus();
    // Hide elements
    const popoverEls = [
      ...document.querySelectorAll('.mcds-popup-portal-root'),
    ];
    // ProseMirror uses `MutationObserver` on its content to detect
    // changes to the html element it manages. The `inert` polyfill causes attribute
    // changes to occur on elements inside the ProseMirror container.
    // ProseMirror has a schema that defines what are valid element and attributes so it
    // attempts fix them. When there's more than 1 child element on the ProseMirror container
    // that the `inert` polyfill mutates, it causes an infinite loop between these two
    // `MutationObserver`. For now, we will ignore the content of ProseMirror.
    // This can be deleted if the `inert` polyfill is no longer used.
    const proseMirrorContainers = [
      ...document.querySelectorAll('.ProseMirror'),
    ];
    const ignoreElements = [root, ...popoverEls, ...proseMirrorContainers];
    // If a CalendarPopover event happens back to back, ensure that the return
    // has a chance to fire before hideAppLevelElements
    const timeout = setTimeout(() => {
      unhideAppLevelElements = hideAppLevelElements(ignoreElements);
    }, 100);
    return () => {
      unhideAppLevelElements();
      // The `inert` polyfill uses a `MutationObserver` to detect changes to
      // the `inert` attribute. We must let the current task end before
      // focusing the previous element. Check if we use the inert polyfill
      // (Element.prototype.inert on polyfill.mailchimp.com) before removing.
      setTimeout(() => {
        (previousFocus as $TSFixMe).focus();
      }, 0);
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    const root = ref.current;

    function handleOutsideClick(e: $TSFixMe) {
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      if (!root.contains(e.target)) {
        onRequestClose();
      }
    }

    document.addEventListener('click', handleOutsideClick, { capture: true });

    return function cleanup() {
      document.removeEventListener('click', handleOutsideClick, {
        capture: true,
      });
    };
  }, [onRequestClose]);

  return (
    <Popup
      targetRef={targetRef}
      // @ts-expect-error TS(2322) FIXME: Type '{ children: Element[]; targetRef: any; class... Remove this comment to see the full error message
      className={stylesheet.popup}
      placement="bottom-end"
      offset={4}
    >
      <Calendar
        {...props}
        aria-describedby={describedbyId}
        ref={ref}
        // @ts-expect-error TS(2322) FIXME: Type '{ "aria-describedby": string; ref: MutableRe... Remove this comment to see the full error message
        onKeyDown={(e: $TSFixMe) => {
          if (e.key === 'Escape' && !e.defaultPrevented) {
            onRequestClose();
          }
        }}
      />
      {/* Relevant to InputDate, not Calendar alone. */}
      <span className="wink-visually-hidden" id={describedbyId}>
        {inputDateMsg}
      </span>
    </Popup>
  );
}

export type InputDateProps = {
  dateFilter?: $TSFixMeFunction;
  disabled?: boolean;
  error?: string;
  format?: string;
  helpText?: React.ReactNode;
  hideLabel?: boolean;
  label?: React.ReactNode;
  max?: $TSFixMe; // TODO: PropTypes.instanceOf(Date)
  min?: $TSFixMe; // TODO: PropTypes.instanceOf(Date)
  miscText?: React.ReactNode;
  onBlur?: $TSFixMeFunction;
  onChange: $TSFixMeFunction;
  onOpenCalendar?: $TSFixMeFunction;
  readOnly?: boolean;
  startDayOfWeek?: string;
  value?: $TSFixMe; // TODO: PropTypes.instanceOf(Date)
};

const InputDate = React.forwardRef<$TSFixMe, InputDateProps>(function InputDate(
  {
    // Default to an "always" filter
    dateFilter = () => true,
    error,
    format: inputFormat,
    max,
    min,
    onChange,
    onOpenCalendar,
    startDayOfWeek,
    value,
    ...props
  },
  forwardedRef,
) {
  // Set current locale based on browser locale. If browser locale is not supported,
  // 'en-US' format will be used or the format provided at implementation. MCDS components will rely on browser locale to decouple logic
  // from the MC Internationalization package because it is also used outside of in-app experiences.
  inputFormat = inputFormat || 'MM-dd-yyyy';
  SUPPORTED_LOCALES_DATE.forEach((locale) => {
    if (Object.values(locale).indexOf(navigator.language) > -1) {
      inputFormat = locale.format;
    }
  });

  const days = TranslatedDays();
  startDayOfWeek = startDayOfWeek || days[0];

  const iconButtonRef = useRef(null);
  // State for tracking user input
  const [inputValue, setInputValue] = useState(() => {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    return value ? format(value, inputFormat) : '';
  });

  // State for user input validity
  const [hasInvalidInput, setHasInvalidInput] = useState(false);
  const [hasOutOfRangeInput, setHasOutOfRangeInput] = useState(false);

  // Popup state
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);

  // Translate default text
  const {
    inputDateOpenMsg,
    inputDateInvalidMsg,
    inputDateInvalidFormatMsg,
  } = TranslateInput();

  // Sync local state from upstream changes
  useEffect(() => {
    setInputValue(() => {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      return value ? format(value, inputFormat) : '';
    });

    setHasInvalidInput(false);
    setHasOutOfRangeInput(false);
  }, [value, inputFormat]);

  return (
    <>
      <Input
        {...props}
        ref={forwardedRef}
        type="text"
        value={inputValue}
        onChange={setInputValue}
        onFocus={() => {
          setHasInvalidInput(false);
          setHasOutOfRangeInput(false);
        }}
        // This keydown event is here to handle an edgecase where a user
        // manually types in a date and hits Enter to submit a Form, avoiding
        // the onBlur which updates the value of the field.
        // @ts-expect-error TS(2322) FIXME: Type '{ ref: ForwardedRef<any>; type: "text"; valu... Remove this comment to see the full error message
        onKeyDown={(event: $TSFixMe) => {
          if (event.key === 'Enter') {
            // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
            const formatedValue = format(value || NOW, inputFormat);
            // This condition is to handle two separate cases:
            // 1) The input is empty and has no value (undefined), the user enters a
            // new value (inputValue) and hits enter.
            // 2) The input had a valid default value at the start, the user enters
            // a new value and hits enter.

            // If any of these two conditions are true, check validation and if
            // it passes validation, we update the value.
            if (value === undefined || inputValue !== formatedValue) {
              event.preventDefault();
              // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
              const parsed = parse(inputValue, inputFormat, value || NOW);
              if (
                !(parsed instanceof Date) ||
                isNaN(parsed.getTime()) ||
                // This is to ensure the parsed date has a full 4-digit year
                // Without it, a year of 202 will be reformatted to 0202.

                // This should still work even if the formatting passed to the
                // 'format' prop of the this component excludes year such as
                // 'MM/DD', because the 'parsed' date will always have a year
                // associated with it.
                parsed.getFullYear().toString().length !== 4
              ) {
                setHasInvalidInput(true);
              } else if (
                (min !== undefined && min > parsed) ||
                (max !== undefined && max < parsed) ||
                !dateFilter(parsed)
              ) {
                setHasOutOfRangeInput(true);
              } else {
                onChange(parsed);
              }
            }
          }
        }}
        onBlur={(event) => {
          const parsed = inputValue
            ? // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
              parse(inputValue, inputFormat, value || NOW)
            : null;

          if (!parsed) {
            onChange(parsed);
          } else if (
            !(parsed instanceof Date) ||
            isNaN(parsed.getTime()) ||
            // This is to ensure the parsed date has a full 4-digit year
            // Without it, a year of 202 will be reformatted to 0202.

            // This should still work even if the formatting passed to the
            // 'format' prop of the this component excludes year such as
            // 'MM/DD', because the 'parsed' date will always have  a year
            // associated with it.
            parsed.getFullYear().toString().length !== 4
          ) {
            setHasInvalidInput(true);
          } else if (
            (min !== undefined && min > parsed) ||
            (max !== undefined && max < parsed) ||
            !dateFilter(parsed)
          ) {
            setHasOutOfRangeInput(true);
          } else {
            onChange(parsed);
          }
          if (props.onBlur) {
            props.onBlur(event);
          }
        }}
        suffixText={
          <IconButton
            ref={iconButtonRef}
            icon={<CalendarIcon />}
            onClick={() => {
              if (onOpenCalendar) {
                onOpenCalendar();
              }
              setIsCalendarVisible((bool) => !bool);
            }}
            label={inputDateOpenMsg}
            disabled={props.disabled || props.readOnly}
          />
        }
        error={
          hasOutOfRangeInput
            ? inputDateInvalidMsg
            : hasInvalidInput
            ? `${inputDateInvalidFormatMsg} ${inputFormat.toLowerCase()}`
            : error
        }
      />
      {isCalendarVisible && (
        <CalendarPopup
          targetRef={iconButtonRef}
          min={min}
          max={max}
          dateFilter={dateFilter}
          // @ts-expect-error TS(2322) FIXME: Type '{ targetRef: MutableRefObject<null>; min: an... Remove this comment to see the full error message
          format={inputFormat}
          value={value || NOW}
          onChange={onChange}
          onRequestClose={() => {
            setIsCalendarVisible(false);
          }}
          startDayOfWeek={startDayOfWeek}
        />
      )}
    </>
  );
});

export default InputDate;
