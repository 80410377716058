const useComboboxFilter = (
  options: $TSFixMe,
  value: $TSFixMe,
  identity = (s: $TSFixMe) => s,
) => {
  const filteredOptions = options.map(identity).filter((option: $TSFixMe) => {
    return option.toLowerCase().includes(value.toLowerCase());
  });

  const exactMatch = filteredOptions.find(
    (option: $TSFixMe) => option.toLowerCase() === value.toLowerCase(),
  );

  return [filteredOptions, exactMatch];
};

export default useComboboxFilter;
