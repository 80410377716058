import React, { createContext, useContext } from 'react';
import cx from 'classnames';
import useId from '@mc/hooks/useId';
import Fieldset from '../Fieldset';

import stylesheet from './Radio.less';

const NO_PROVIDER = Symbol('NO_PROVIDER');

const OnChangeContext = createContext(NO_PROVIDER);
const SelectedValueContext = createContext(NO_PROVIDER);
const ErrorContext = createContext(NO_PROVIDER);

export type RadioGroupProps = {
  children: React.ReactNode;
  error?: string;
  hideLabel?: boolean;
  label?: React.ReactNode;
  legend?: React.ReactNode;
  onChange: $TSFixMeFunction;
  value?: string | number | boolean;
};

/** Wrapper for Radio Buttons */
function RadioGroup({
  children,
  error,
  hideLabel = false,
  legend,
  label,
  value,
  onChange,
  // provided by a generic interface. We don't need to account
  // for PropTypes or usage, so we'll just disable linting here.
  // @ts-expect-error TS(2339) FIXME: Property 'miscText' does not exist on type 'Props'... Remove this comment to see the full error message
  // eslint-disable-next-line react/prop-types, no-unused-vars
  miscText,
  ...props
}: RadioGroupProps) {
  const errorId = useId();

  return (
    // @ts-expect-error TS(2322) FIXME: Type '$TSFixMeFunction' is not assignable to type ... Remove this comment to see the full error message
    <OnChangeContext.Provider value={onChange}>
      {/* @ts-expect-error TS(2322) FIXME: Type 'string | number | boolean | undefined' is no... Remove this comment to see the full error message */}
      <SelectedValueContext.Provider value={value}>
        {/* @ts-expect-error TS(2322) FIXME: Type '{ errorId: string; error: string | undefined... Remove this comment to see the full error message */}
        <ErrorContext.Provider value={{ errorId, error }}>
          <Fieldset
            {...props}
            hideLabel={hideLabel}
            legend={label || legend}
            className={cx({
              [stylesheet.error]: !!error,
            })}
          >
            {children}
            {/* Error message for Radio group */}
            {error && (
              <div id={errorId} className={stylesheet.errorMessage}>
                {error}
              </div>
            )}
          </Fieldset>
        </ErrorContext.Provider>
      </SelectedValueContext.Provider>
    </OnChangeContext.Provider>
  );
}

export function useRadioOnChange() {
  const context = useContext(OnChangeContext);
  if (context === NO_PROVIDER) {
    throw new Error('<Radio>s must be wrapped by a <RadioGroup>.');
  }
  return context;
}

export function useRadioSelectedValue() {
  const context = useContext(SelectedValueContext);
  if (context === NO_PROVIDER) {
    throw new Error('<Radio>s must be wrapped by a <RadioGroup>.');
  }
  return context;
}

export function useRadioError() {
  const context = useContext(ErrorContext);
  if (context === NO_PROVIDER) {
    throw new Error('<Radio>s must be wrapped by a <RadioGroup>.');
  }
  return context;
}

export default RadioGroup;
