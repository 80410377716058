import React from 'react';
import stylesheet from './AnnouncementCardTunnel.less';

export type AnnouncementCardTunnelProps = {
  /** Default `id` is configured and used within `Main/core/Shell.js`.
   * If you are not using the classic or redesign shell, you may create a
   * custom portal by importing this component and setting your own custom id. */
  id?: string;
};

/** Toast wrapper for accessibility purposes. */
const AnnouncementCardTunnel = React.forwardRef<
  HTMLDivElement,
  AnnouncementCardTunnelProps
>(function AnnouncementCardTunnel(
  { id = 'portal-announcement' },
  forwardedRef,
) {
  return (
    <div className={stylesheet.root} role="status" id={id} ref={forwardedRef} />
  );
});

export default AnnouncementCardTunnel;
