import React from 'react';
import cx from 'classnames';
import { mcdsFlagCheck } from '@mc/wink/helpers/utils-ts';
import ContentArea from '../ContentArea';
import Heading from '../Heading';
import SwitcherLayout, { SwitcherLayoutProps } from '../SwitcherLayout';
import StackLayout from '../StackLayout';
import stylesheet from './Hero.css';

const DefaultLayout = (props: SwitcherLayoutProps) => {
  return <SwitcherLayout gap={8} {...props} />;
};

export type HeroProps = {
  children: React.ReactNode;
  layout?: typeof SwitcherLayout;
  secondary?: React.ReactNode;
  size?: 'standard' | 'narrow' | 'wide';
  textWrap?: 'wrap' | 'nowrap' | 'balance' | 'pretty';
  title: React.ReactNode;
  variant?:
    | 'coconut'
    | 'cornflower'
    | 'cucumber'
    | 'radish'
    | 'squash'
    | 'yam'
    | 'jasmine';
};

const Hero = React.forwardRef<HTMLDivElement, HeroProps>(function Hero(
  {
    variant,
    title,
    size,
    secondary,
    children,
    layout: Layout = DefaultLayout,
    textWrap,
  },
  forwardedRef,
) {
  return (
    <div
      className={cx(
        stylesheet.root,
        secondary && stylesheet.withSecondary,
        variant && stylesheet[variant],
      )}
      ref={forwardedRef}
    >
      <ContentArea size={size} className={stylesheet.content}>
        <Layout>
          <StackLayout gap={6} style={{ flexGrow: 2, alignSelf: 'center' }}>
            <Heading
              level={2}
              appearance={
                mcdsFlagCheck('xp_project_runway_design_foundation')
                  ? 'heading-display'
                  : 'heading-1'
              }
              textWrap={textWrap}
            >
              {title}
            </Heading>
            {children}
          </StackLayout>
          {secondary && (
            <div className={stylesheet.secondaryContent} style={{ order: 1 }}>
              {secondary}
            </div>
          )}
        </Layout>
      </ContentArea>
    </div>
  );
});

export default Hero;
