import React, { useContext } from 'react';
import cx from 'classnames';

import { ModalIdContext } from './Modal';

import stylesheet from './Modal.less';

export type ModalHeaderProps = {
  children?: React.ReactNode;
  className?: string;
};

const ModalHeader = ({ children, className, ...rest }: ModalHeaderProps) => {
  const id = useContext(ModalIdContext);

  return (
    <div className={cx(stylesheet.header, className)} {...rest}>
      {/* @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'string |... Remove this comment to see the full error message */}
      <h1 id={id} className={stylesheet.title}>
        {children}
      </h1>
    </div>
  );
};

export default ModalHeader;
