import React from 'react';
import useId from '@mc/hooks/useId';

import stylesheet from './ActionList.less';

export type ActionListGroupProps = {
  /** Children should be ActionListItems. */
  children?: React.ReactNode;
  /** Title of each action item */
  label?: string;
};

const ActionListGroup = ({ children, label }: ActionListGroupProps) => {
  const id = useId();
  return (
    <div className={stylesheet.group} role="group" aria-labelledby={id}>
      <div id={id} className={stylesheet.groupLabel}>
        {label}
      </div>
      {children}
    </div>
  );
};

export default ActionListGroup;
