function emulateSelectKeyboardSearch(
  event: $TSFixMe,
  { options, isExpanded, onHighlight, onSelect, onToggle }: $TSFixMe,
) {
  // Emulating native select behavior
  // Alphanumeric keys should highlight or select keys by the key
  // e.g. given options of ['Apple', 'Banana', 'Peach', 'Apricot', 'Mango', 'Avocado']
  // Each time 'a' is pressed it would cycle to the options 'Apple', 'Apricot', 'Avocado', 'Apple'

  // destructuring key because sYnThEtiC eVeNts
  const { key } = event;

  // Space bar should act like the enter key
  if (key === ' ') {
    event.preventDefault();
    if (isExpanded) {
      const selected = options.find((option: $TSFixMe) => option.isHighlighted);
      onSelect(selected ? selected.value : undefined);
    } else {
      onToggle();
    }
  }

  if (/^[a-z0-9]$/i.test(key)) {
    event.preventDefault();
    const highlightedIndex = options.findIndex(
      (option: $TSFixMe) => option.isHighlighted,
    );
    // search only the options past the currently highlighted value
    let nextOption = options
      .slice(highlightedIndex + 1)
      .find((option: $TSFixMe) => {
        return (
          !option.disabled &&
          option.label &&
          option.label.toLowerCase().startsWith(key.toLowerCase())
        );
      });

    // No next option was found so search the set before the highlighted index
    if (!nextOption) {
      nextOption = options
        .slice(0, highlightedIndex)
        .find((option: $TSFixMe) => {
          return (
            !option.disabled &&
            option.label &&
            option.label.toLowerCase().startsWith(key.toLowerCase())
          );
        });
    }

    if (nextOption) {
      if (isExpanded) {
        onHighlight(nextOption.value);
      } else {
        onSelect(nextOption.value);
      }
    }
  }
}

export default emulateSelectKeyboardSearch;
