/* eslint-disable formatjs/no-literal-string-in-jsx */
import React, { ClipboardEvent, FocusEvent, useEffect, useState } from 'react';
import cx from 'classnames';

import pluralize from '@mc/fn/pluralize';
import useId from '@mc/hooks/useId';
import {
  ariaDescribedByIds,
  ariaLabelledByIds,
  ERROR_MUST_PROVIDE_LABEL,
  formatError,
} from '../utils';

import { TranslateInput } from './TranslateInput';
import stylesheet from './Input.less';

export type InputProps = {
  /** Pass an element's ID to include its text content as part of this component's accessible name. */
  'aria-labelledby'?: string;
  /** Makes the input unusable and un-clickable. */
  disabled?: boolean;
  /** Will show in place of help text if defined also applies invalid style treatment. */
  error?: string | boolean | React.ReactNode;
  /** Text that appears below the input */
  helpText?: React.ReactNode;
  /** Visually hides the label provided by the `label` prop. */
  hideLabel?: boolean;
  /** An id to use for the input (this is typically set automatically) */
  id?: string;
  /** The label of the input. */
  label?: React.ReactNode;
  /** Max character limit. Strongly discouraged due to poor accessibility. Prefer `suggestedMaxLength` plus length validations. */
  maxLength?: number;
  /** Text that appears above the input and right of the label. Usually shows Required state of the input. */
  miscText?: React.ReactNode;
  /** Triggered when the field no longer contains focus */
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  /** Triggers when the input value is changed. This callback would usually handle updating the value prop. */
  onChange: (value: string) => void;
  /** Triggered when the field has focus */
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  /** If passed in, this will render to the left of the input. An example of this might be a currency indicator or a url */
  prefixText?: React.ReactNode;
  /** A read-only input field cannot be modified (however, a user can tab to it, highlight it, and copy the text from it). */
  readOnly?: boolean;
  /** Shows the character count in place of miscText when focused */
  showCharacterCount?: boolean;
  /** If passed in, this will render to the right of the input. An example of this might by a trailing currency type */
  suffixText?: React.ReactNode;
  /** Shows the character count in place of `miscText` on focus. */
  suggestedMaxLength?: number;
  /** Just the HTML attribute for `<input>`s. Defaults to `text`. Feel free to use any of the type variants of 'text' ('email', 'password', 'search', 'tel', 'url')
   *  but please do not use `checkbox`, `radio`, `file`, `submit`, `reset`, `hidden`, or the date variants. These are handled by other components in `@mc`.  */
  type?: 'text' | 'number' | 'password' | 'email' | 'search' | 'tel' | 'url';
  /** @ignore */
  unsafe_renderExtraContent?: React.ReactNode;
  /** The current value of the input. This component is uncontrolled so it is expected that a parent component will update this value when `onChange` is called. */
  value?: string;
  className?: string;
  inputMode?: 'numeric' | 'decimal' | 'tel';
  pattern?: string;
  placeholder?: string;
  minLength?: number;
  onPaste?: (event: ClipboardEvent<HTMLInputElement>) => void;
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(function Input(
  {
    'aria-labelledby': ariaLabelledBy,
    type = 'text',
    className,
    disabled = false,
    readOnly = false,
    helpText,
    hideLabel = false,
    error,
    label,
    onChange,
    prefixText,
    miscText,
    suggestedMaxLength = 0,
    suffixText,
    onFocus = () => {},
    onBlur = () => {},
    unsafe_renderExtraContent,
    id: idFromProps,
    value = '',
    ...props
  },
  forwardedRef,
) {
  const _id = useId();
  const labelId = useId();
  const helpTextId = useId();
  const prefixTextId = useId();
  const suffixTextId = useId();
  const [hasFocus, setHasFocus] = useState(false);
  const id = idFromProps || _id;

  const count = (value && value.length) || 0;
  const remaining = suggestedMaxLength - count;

  // Init translation
  const {
    characterMsg,
    charactersMsg,
    availableMsg,
    usedMsg,
  } = TranslateInput();

  // Check that either label or ariaLabelledBy contain a value

  useEffect(() => {
    if (!label && !ariaLabelledBy && __DEV__) {
      throw new Error(formatError(ERROR_MUST_PROVIDE_LABEL, 'Input'));
    }
  }, [label, ariaLabelledBy]);

  return (
    <div
      className={cx(stylesheet.root, className, {
        [stylesheet.error]: !!error,
      })}
    >
      <div className={stylesheet.before}>
        {label && (
          <label
            className={cx(
              'mcds-label-default',
              hideLabel && 'wink-visually-hidden',
            )}
            id={labelId}
            htmlFor={id}
          >
            {label}
          </label>
        )}
        {suggestedMaxLength > 0 && (hasFocus || remaining < 0) ? (
          <span
            className={cx(stylesheet.secondary, {
              [stylesheet.errorMessage]: remaining < 0,
            })}
            role="status"
            aria-live="polite"
            aria-atomic="true"
          >
            <span className="wink-visually-hidden">
              {/* This is translated but linter error still comes up. eslint format disabled for this file. */}
              {`${count} ${pluralize(
                characterMsg,
                charactersMsg,
                count,
              )} ${usedMsg}, ${suggestedMaxLength} ${pluralize(
                characterMsg,
                charactersMsg,
                suggestedMaxLength,
              )} ${availableMsg}`}
            </span>
            <span aria-hidden="true">
              {count}/{suggestedMaxLength}
            </span>
          </span>
        ) : miscText ? (
          typeof miscText === 'string' ? (
            <span className={stylesheet.secondary}>{miscText}</span>
          ) : (
            <div className={cx(stylesheet.secondary, stylesheet.nonText)}>
              {miscText}
            </div>
          )
        ) : null}
      </div>
      {unsafe_renderExtraContent}
      <div
        className={cx(stylesheet.inputWrapper, {
          [stylesheet.disabledInput]: disabled,
          [stylesheet.readOnlyInput]: readOnly,
        })}
      >
        {prefixText && (
          <span
            id={prefixTextId}
            className={stylesheet.inputPrefix}
            data-input-prefix
          >
            {prefixText}
          </span>
        )}
        <input
          type={type}
          id={id}
          // We need to handle three cases:
          //
          // 1. Only pass a `label`. Since we're using a native label element,
          //    pointing `aria-labelledby` to the existing label element is
          //    unnecessary.
          // 2. Only pass an `aria-labelledby`. We don't render a label element.
          // 3. Pass both a `label` and `aria-labelledby`. We refer to both in
          //    the `aria-labelledby` attribute.
          aria-labelledby={ariaLabelledByIds(
            ariaLabelledBy,
            ariaLabelledBy && label && labelId,
          )}
          aria-describedby={ariaDescribedByIds(
            (error || helpText) && helpTextId,
            prefixText && prefixTextId,
            suffixText && suffixTextId,
          )}
          aria-invalid={error ? 'true' : 'false'}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          ref={forwardedRef}
          disabled={disabled}
          readOnly={readOnly}
          onBlur={(e) => {
            setHasFocus(false);
            onBlur(e);
          }}
          onFocus={(e) => {
            setHasFocus(true);
            onFocus(e);
          }}
          value={value}
          {...props}
        />
        {suffixText && (
          <span
            id={suffixTextId}
            className={stylesheet.inputSuffix}
            data-input-suffix
          >
            {suffixText}
          </span>
        )}
      </div>
      {error ? (
        <div
          id={helpTextId}
          className={cx(stylesheet.after, stylesheet.errorMessage)}
        >
          {error}
        </div>
      ) : helpText ? (
        <div
          id={helpTextId}
          className={cx(stylesheet.after, stylesheet.secondary)}
        >
          {helpText}
        </div>
      ) : null}
    </div>
  );
});

export default Input;
