import React, { useCallback } from 'react';
import cx from 'classnames';

import { TickCircleFillIcon } from '@mc/wink-icons';
import useId from '@mc/hooks/useId';
import { ariaDescribedByIds } from '../utils';

import Text from '../Text';
import Badge from '../Badge';

import StackLayout from '../StackLayout';
import stylesheet from './SelectionChoiceCard.less';
import ChoiceCardGroup, {
  useChoiceOnChange,
  useChoiceSelectedValues,
  useChoiceInputTypeValue,
} from './ChoiceCardGroup';

export type SelectionChoiceCardProps = {
  additionalCopy?: string;
  badge?: string;
  badgeType?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  id?: string;
  image?: string | React.ReactElement;
  label: React.ReactNode;
  value?: string | number | boolean;
};

const SelectionChoiceCard = React.forwardRef<$TSFixMe, SelectionChoiceCardProps>(
  function SelectionChoiceCard(
    {
      // @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type 'Props... Remove this comment to see the full error message
      className,
      badge,
      badgeType,
      children,
      id,
      image,
      label,
      value,
      disabled = false,
      additionalCopy,
      ...props
    },
    forwardedRef,
  ) {
    const selectedValues = useChoiceSelectedValues();
    const onChange = useChoiceOnChange();
    /* For accessibility, error messages have better support when associated to form controls vs fieldset/legends.
     * Pulling in error id to associate it with aria-describedby.
     * Reference: https://www.last-child.com/legend-aria-describedby/
     */
    // @ts-expect-error TS(2339) FIXME: Property 'inputType' does not exist on type 'Symbo... Remove this comment to see the full error message
    const { inputType, errorId, error } = useChoiceInputTypeValue();
    const inputId = useId();
    const labelId = useId();
    const additionalId = useId();
    const descriptionId = useId();
    const describedBy = ariaDescribedByIds(
      children && descriptionId,
      error && errorId,
      additionalCopy && additionalId,
    );
    const [type, variant] = inputType.split('-');
    id = id || inputId;
    const isSimple = variant === 'hidden';
    const isHorizontal = variant === 'horizontal';
    const isIcon = typeof image !== 'string';

    const isSelected = useCallback(() => {
      return Array.isArray(selectedValues)
        ? selectedValues.includes(value)
        : // @ts-expect-error TS(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
          selectedValues === value;
    }, [selectedValues, value]);

    // Set specific wrapper if an image or icon is passed based on prop type.
    const cardImage =
      image &&
      (!isIcon ? (
        <div className={stylesheet.imageContainer}>
          <img src={image} alt="" className={stylesheet.image} />
        </div>
      ) : (
        <div className={stylesheet.icon}>{image}</div>
      ));

    return (
      <StackLayout
        className={cx(stylesheet.wrapper, className, {
          [stylesheet.checked]: isSelected(),
          [stylesheet.disabled]: disabled,
          [stylesheet.simple]: isSimple,
          [stylesheet.horizontal]: isHorizontal,
        })}
        gap={0}
      >
        <div className={cx(stylesheet.selection, className)}>
          <label htmlFor={id} className={stylesheet.card} {...props}>
            <div className="wink-visually-hidden">
              <input
                id={id}
                className={stylesheet.input}
                type={type}
                // @ts-expect-error TS(2322) FIXME: Type 'string | number | boolean | undefined' is no... Remove this comment to see the full error message
                value={value}
                checked={isSelected()}
                // @ts-expect-error TS(2349) FIXME: This expression is not callable.
                onChange={() => onChange(value)}
                disabled={disabled}
                ref={forwardedRef}
                aria-describedby={describedBy}
                aria-labelledby={labelId}
              />
            </div>

            {isSelected() ? (
              // Display Selected Checkmark
              <div
                className={cx({
                  [stylesheet.imageContainer]: !isIcon,
                })}
              >
                <TickCircleFillIcon
                  alt="Selected"
                  className={cx(stylesheet.checkIcon, {
                    'wink-visually-hidden': isSimple,
                  })}
                />
              </div>
            ) : isHorizontal && !cardImage ? (
              // Display unselected circle for horizontal view
              <span className={stylesheet.unselectedCircle} />
            ) : (
              cardImage
            )}

            {/* Badges are only available in vertical default view.  */}
            {badge && !isHorizontal ? (
              <div>
                {/* @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message */}
                <Badge className={stylesheet.badge} type={badgeType}>
                  {badge}
                </Badge>
              </div>
            ) : null}
            <div className={stylesheet.copy}>
              <div id={labelId} className={stylesheet.label}>
                {label}
              </div>
              {children && (
                <div className={stylesheet.description} id={descriptionId}>
                  {children}
                </div>
              )}
            </div>
          </label>
        </div>
        {additionalCopy && (
          <div className={stylesheet.additional}>
            <Text id={additionalId} appearance="small-secondary">
              {additionalCopy}
            </Text>
          </div>
        )}
      </StackLayout>
    );
  },
);

export { SelectionChoiceCard as default, ChoiceCardGroup };
